<template>
  <div class="document-capture pos-r">
    <BackButton :isWhite="true" />
    <video
      id="video"
      v-if="!useRegulaReader"
      :class="{ blur: isLoading }"
      ref="video"
      src="#"
      autoplay
      muted
      playsinline
    ></video>
    <div v-if="!useRegulaReader" class="image-preview">
      <canvas ref="canvas"></canvas>
    </div>
    <img src="@/assets/docMask.svg" v-if="!useRegulaReader" class="mask " />
    <FaceMask class="face-mask" v-if="!useRegulaReader" :class="{ blur: isLoading }" />
    <div class="instructions" :class="{ blur: isLoading }" v-if="useRegulaReader">
      <span class="instructions-intro">{{ $t("message.takeDocumentPicture") }}</span>
      <div v-for="(instruction, index) in instructions" :key="index" class="instruction-wrapper">
        <div class="instruction-index">{{ index + 1 }}</div>
        <span class="instruction">{{ $t(instruction) }}</span>
      </div>
    </div>

    <div
      class="instructionsCamera"
      :class="{ blur: isLoading, bottom: '1.5rem', left: '1.5rem' }"
      v-if="!useRegulaReader"
    >
      <span class="instructions-intro">{{ $t("message.takeDocumentPicture") }}</span>
      <div
        v-for="(instruction, index) in cameraInstructions"
        :key="index"
        class="instruction-wrapper"
      >
        <div class="instruction-index">{{ index + 1 }}</div>
        <span class="instruction">{{ $t(instruction) }}</span>
      </div>
    </div>
    <img :src="docImg" />
    <img
      class="take-photo"
      :class="{ blur: isLoading }"
      src="@/assets/ic_photo.svg"
      alt="Camera icon"
      @click="takeScreenShot"
    />
    <transition name="fade">
      <Loader v-show="isLoading" />
    </transition>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton.vue";
import Loader from "@/components/Loader.vue";
import axios from "axios";
import {
  Light,
} from "@regulaforensics/document-reader-webclient";

export default {
  name: "DocumentCapture",
  components: {
    BackButton,
    Loader
  },
  props: ["isLoading", "videoPlayStatus"],
  data() {
    return {
      instructions: ["message.removePlastic", "message.scanDocumentFront", "message.clickCapture"],
      cameraInstructions: ["message.faceSide", "message.frameDoc", "message.removePlastic"],
      cameraId: null,
      stream: null,
      photoUrl: null,
      isPassport: false,
      front: true,
      document: {},
      useRegulaReader: false,
      docImg: null,
      secondAttempt: false
    };
  },
  computed: {
    isDoingCheckin() {
      return this.$store.getters.currentProcess === "checkin";
    }
  },
  methods: {
    initCamera() {
      if (this.isDoingCheckin && !this.useRegulaReader) {
        const cameraId = localStorage.getItem("documentCamera");
        if (!cameraId) {
          this.$router.push({ name: "ErrorConfig" });
          return;
        }
        this.cameraId = cameraId;
        this.getStream();
      }
    },
    getStream() {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: {
              exact: this.cameraId
            },
            width: 720,
            height: 480
          }
        })
        .then(async data => {
          await this.streamConnectedHandler(data);
        })
        .catch(() => {});
    },
    streamConnectedHandler(stream) {
      this.stream = stream;
      this.$refs.video.srcObject = stream;
      this.$emit("stream-connected");
    },
    async takeScreenShot() {
      if (this.useRegulaReader) {
        await this.captureDocument();
      } else {
        this.startProcessingPicture();
      }
    },
    startProcessingPicture() {
      this.$emit("start-processing");
      this.photoUrl = null;
      this.processPicture();
    },
    processPicture() {
      this.$refs.canvas.width = this.$refs.video.videoWidth;
      this.$refs.canvas.height = this.$refs.video.videoHeight;
      this.$refs.canvas
        .getContext("2d")
        .drawImage(
          this.$refs.video,
          this.$refs.canvas.width / 4,
          0,
          this.$refs.canvas.width / 2,
          this.$refs.canvas.height,
          this.$refs.canvas.width / 4,
          0,
          this.$refs.canvas.width / 2,
          this.$refs.canvas.height
        );
      this.finishProcessingPicture();
    },
    finishProcessingPicture() {
      const imageData = this.$refs.canvas.toDataURL("image/png");
      this.$emit("finish-processing", { img: imageData });
    },
    async captureDocument() {
      try {
        this.$emit("start-processing");

        await axios.get("http://localhost/Regula.SDK.Api/Methods/GetImages");

        await new Promise(resolve => {
          return setTimeout(() => resolve(), 6000);
        });

        const imagesRes = await Promise.all([
          axios.get("http://localhost/Regula.SDK.Api/Methods/GetReaderFileImage?AIdx=0"),
          axios.get("http://localhost/Regula.SDK.Api/Methods/GetReaderFileImage?AIdx=1"),
          axios.get("http://localhost/Regula.SDK.Api/Methods/GetReaderFileImage?AIdx=2")
        ]);

        const images = [
          { ImageData: imagesRes[0].data, light: Light.IR, page_idx: this.front ? 0 : 1 },
          { ImageData: imagesRes[1].data, light: Light.WHITE, page_idx: this.front ? 0 : 1 },
          { ImageData: imagesRes[2].data, light: Light.UV, page_idx: this.front ? 0 : 1 }
        ];
        
        this.document[this.front ? "front" : "back"] = images;
        
        if (!this.front) {
          const securityValidationResult = await axios.get('http://localhost/Regula.SDK.Api/Settings/GetPropertyValue?propertyName=CheckStatusSecurity');

          if (!securityValidationResult.data) {
            throw new Error();
          }

          const result = await axios.get('http://localhost/Regula.SDK.Api/Methods/CheckReaderResultJSON?AType=15&AIdx=0');

          const jsonResult = JSON.parse(result.data);

          let fullName, docNumber, dateOfBirth;

          jsonResult.ListVerifiedFields.pFieldMaps.forEach(({ FieldType, Field_Visual }) => {
            if (FieldType === 5) dateOfBirth = Field_Visual;
            if (FieldType === 25) fullName = Field_Visual;
            if (FieldType === 495) docNumber = Field_Visual;
          });
          
          await axios.post("http://localhost/Regula.SDK.Api/Methods/ClearResults");

          this.$emit("finish-processing", {
            img: this.document.front[1].ImageData,
            params: {
              docNumber,
              fullName,
              dateOfBirth,
            }
          });
        } else {
          this.$emit("stop-loading");
        }

        this.front = !this.front;
      } catch (e) {
        this.$emit("stop-loading");
        await this.handleRegulaReaderError();
      }
    },
    async handleMounted() {
      if (!this.useRegulaReader) {
        this.initCamera();
      }
    },
    async handleRegulaReaderError() {
      if (!this.secondAttempt) {
        this.$toast.error(
          "Não foi possível processar o seu documento. Por favor, tente novamente."
        );

        this.front = true;
        this.document = {};
        this.docImg = null;
        this.secondAttempt = true;
      } else {
        this.$toast.error(
          "Não foi possível processar o seu documento. Por favor, dirija-se à recepção do hotel."
        );

        this.$router.push("/");
      }
    }
  },
  watch: {
    videoPlayStatus() {
      if (this.videoPlayStatus) {
        this.$refs.video.play();
      } else {
        this.$refs.video.pause();
      }
    },
    front() {
      const newInstruction = this.instructions;

      newInstruction[1] = this.front ? "message.scanDocumentFront" : "message.scanDocumentBack";

      this.instructions = [];

      this.instructions = newInstruction;
    }
  },
  mounted() {
    this.useRegulaReader = localStorage.getItem("settings.useRegulaReader") === "1";
    this.handleMounted();
  },
  beforeDestroy() {
    if (this.useRegulaReader) {
      axios.post("http://localhost/Regula.SDK.Api/Methods/ClearResults");
    }
    if (this.stream !== null) {
      this.stream.getTracks()[0].stop();
    }
  }
};
</script>

<style lang="scss" scoped>
.blur {
  filter: blur(8px);
}

.document-capture {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: black;

  .modal {
    position: absolute;
  }

  .mask,
  .instructions,
  .take-photo {
    position: absolute;
  }

  .image-preview {
    display: none;
  }

  #video {
    height: 115%;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50.1%) rotate(180deg);
  }

  .take-photo {
    width: 7rem;
    bottom: 3.5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    cursor: pointer;
  }

  .mask {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  .instructions {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 4px;
    position: absolute;
    top: 40vh;
    left: 15vw;
    z-index: 2;

    .instructions-intro {
      font-size: 2.14rem;
      color: $white;
      margin-bottom: 1.5rem;
    }

    .instruction-wrapper {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      .instruction-index {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        width: 30px;
        height: 30px;
        border: 1px solid $white;
        font-size: 1.3rem;
        color: $white;
      }

      .instruction {
        color: white;
        margin-left: 1rem;
        font-size: 2rem;
      }
    }
  }

  .instructionsCamera {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 4px;
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    z-index: 2;

    .instructions-intro {
      font-size: 2.14rem;
      color: $white;
      margin-bottom: 1.5rem;
    }

    .instruction-wrapper {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      .instruction-index {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        width: 30px;
        height: 30px;
        border: 1px solid $white;
        font-size: 1.3rem;
        color: $white;
      }

      .instruction {
        color: white;
        margin-left: 1rem;
        font-size: 2rem;
      }
    }
  }
}
</style>
