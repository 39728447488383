<template>
  <PreCheckinStructure :dotActive="'four'" :isLoading="isLoading" :backButton="true">
    <div slot="center" class="document-list-holder">
      <span>{{ $t("message.chooseDocument") }}</span>
      <div class="document-list btn-container">
        <button
          v-for="item in documentList"
          @click="selectDocument(item)"
          class="squared"
          :class="item.selected ? 'active' : ''"
          :key="item.name"
          type="button"
        >
          {{ item.name }}
        </button>
      </div>
    </div>
    <div v-if="hasDocumentSelection" class="selfie-container" slot="bottom">
      <div class="selfie-container" slot="center">
        <div v-if="isMobile">
          <label for="open-cam">
            <img class="selfie-button" src="@/assets/ic_photo.svg" alt="Selfie button" />
          </label>
          <input
            id="open-cam"
            type="file"
            accept="image/*;capture=camera"
            class="d-none"
            @change="photoSelectedHandler"
          />
        </div>
        <div class="actions" v-else>
          <img
            class="selfie-button"
            src="@/assets/ic_photo.svg"
            alt="Selfie button"
            width="200"
            @click="openCameraHandler"
          />
          <label for="open-file">
            <img
              class="selfie-button file"
              src="@/assets/icons/ic_file.svg"
              alt="Selfie button"
              width="200"
            />
          </label>
          <input
            id="open-file"
            type="file"
            accept="image/*"
            class="d-none"
            @change="photoSelectedHandler"
          />
        </div>
        <span>{{
          isMobile ? $t("message.selfiePreCheckin") : $t("message.selfiePreCheckinDesk")
        }}</span>
      </div>
      <Camera
        v-if="showCamera"
        :isDoc="true"
        :instructions="instructions"
        :title="title"
        @close="closeCameraHandler"
        @confirm-picture="documentRecognitionHandler"
      />
    </div>
  </PreCheckinStructure>
</template>
<script>
import PreCheckinStructure from "@/components/PreCheckinStructure";
import Camera from "@/components/precheckin/Camera.vue";

export default {
  name: "DocumentPic",
  components: {
    PreCheckinStructure,
    Camera
  },
  data() {
    return {
      isLoading: false,
      showCamera: false,
      title: this.$t("message.takeDocumentPicture"),
      instructions: ["message.faceSide", "message.frameDoc", "message.removePlastic"],
      documentList: [
        {
          name: "RG",
          selected: false
        },
        {
          name: "CNH",
          selected: false
        },
        {
          name: this.$t("message.passport"),
          selected: false
        }
      ]
    };
  },
  computed: {
    selected() {
      const item = this.documentList.find(item => item.selected);
      if (item) {
        return item.name;
      }
      return null;
    },
    hasDocumentSelection() {
      return this.selected !== null;
    },
    isMobile() {
      let check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    getDocument() {
      return this.$store.getters.documentDataPhoto;
    }
  },
  methods: {
    selectDocument(document) {
      this.documentList.forEach(item => {
        if (item.name == document.name) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
    },
    openCameraHandler() {
      this.showCamera = true;
    },
    closeCameraHandler() {
      this.showCamera = false;
    },
    photoSelectedHandler(e) {
      const file = e.target.files[0];
      this.documentRecognitionHandler(file);
    },
    documentRecognitionHandler(file) {
      const form = new FormData();
      form.append("file", file);
      this.isLoading = true;

      this.$API.document
        .documentRecognition(form)
        .then(response => {
          console.log(response.data);
          this.$store.commit("SET_DOCUMENT_DATA", response.data);
          this.$router.push({ name: "Personal" });
        })
        .catch(() => {
          this.$alert("error", this.$t("alert.tryAgain"));
          this.closeCameraHandler();
          this.isLoading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.document-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20px;

  button.squared {
    width: 100%;
    margin-top: 15px;
    font-size: 18px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.5s;

    &:first-child {
      margin-top: 0;
    }
  }
}

.document-list-holder {
  span {
    font-size: 16px;
    color: $white;
  }
}

.selfie-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  span {
    margin-top: 20px;
    font-size: 18px;
    color: $white;
    text-align: center;
  }

  .actions {
    display: flex;

    img {
      width: 100px;
      cursor: pointer;

      &:last-child {
        margin-left: 20px;
      }
    }
  }
}

@media (min-width: 768px) {
  .document-list-holder {
    span {
      font-size: 20px;
    }
  }

  .selfie-container {
    span {
      font-size: 20px;
    }

    .actions {
      img {
        width: 140px;

        &:last-child {
          margin-left: 20px;
        }
      }
    }
  }
}

@media (min-width: 1400px) {
  .document-list-holder {
    span {
      font-size: 24px;
    }
  }

  .selfie-container {
    span {
      font-size: 24px;
    }

    .actions {
      img {
        width: 200px;
      }
    }
  }
}
</style>
