export default {
  message: {
    slug: "Fill in with Slug",
    apikey: "Enter the API key",
    faceCameraNav: "Facial Camera",
    documentCameraNav: "Document Camera",
    hotelConfigNav: "Settings",
    languageNav: "Language",
    faceCameraTitle: "Facial Camera Settings",
    documentCameraTitle: "Document Camera Settings",
    hotelConfigTitle: "Hotel Settings",
    blockCheckin: "Blocked Checkin by time",
    blockCheckout: "Blocked Checkout by time",
    doFacialRecognition: "Do facial recognition?",
    smsEnabled: "Enable SMS Sending?",
    magnetizeSystem: "Choose magnetization system",
    encoderAddress: "EncoderPMS Adress",
    select: "Select",
    save: "Save",
    portuguese: "Portuguese",
    english: "English",
    spanish: "Spanish",
    systemLangugeTitle: "System Language",
    languagePlaceHolder: "Choose a language",
    successSave: "Saved successfully!",
    sameLanguage: "This Language is already selected",
    selectCheckin: "Select your checkin.",
    selectAnOption: "Select an option",
    selectKey: "Select a key",
    fixedCheckinMessage: "Check-in from 2pm",
    variableCheckinMessage: "Check-in from {time}",
    variableCheckoutMessage: "Check-out until {time}",
    start: "Start",
    doCheckinCheckout: "Check-in or Check-out here",
    cpfService: "Service available for booking already registered at the Hotel",
    back: "Back",
    next: "Next",
    continue: "Continue",
    guestName: "Name",
    reservationNumber: "Reservation Nº",
    apartmentType: "Apartment",
    apartmentNumber: "Apartment Nº",
    enjoyYourStay: "Enjoy your stay",
    welcomeCaps: "WELCOME",
    thankYouCaps: "THANK YOU",
    seeYouSoon: "See You soon",
    reportName: "Report your first and last name to identify your reservation",
    exit: "Exit",
    doCheckout: "Check-out",
    okCheckout: "Checkout Completed. See you later!",
    doCheckin: "Checkin",
    reportDocument: "Report your number for locating",
    checkoutDate: "Report your checkout date",
    yes: "Yes",
    no: "No",
    formCorona: "Coronavirus research form",
    recentTravel: "Recent international travel? (last 14 days)?",
    personCovid:
      "Have you had contact with a person suspected or diagnosed with the new coronavirus (COVID-19)?",
    symptomsCovid: "Do you have any of the following symptoms?",
    symptomFever: "Fever",
    symptomThroat: "Sore throat",
    symptomCough: "Cough",
    symptomBreathe: "Shortness of breathe",
    symptomMuscle: "Muscle aches",
    symptomDiarrhea: "Diarrhea",
    finish: "Finish",
    selectGuest: "Dear guest, would you like to check-in for other guests?",
    fullName: "Full Name",
    birth: "Date of birth",
    genre: "Genre",
    male: "Male",
    female: "Female",
    neutral: "Others",
    cpf: "CPF",
    celNumber: "Cellphone number",
    email: "E-mail",
    confirmDetails: "Fill your details",
    cep: "Zip Code",
    address: "Address",
    addressNumber: "Number",
    addressComplement: "Complement",
    neighborhood: "Neighborhood",
    city: "City",
    state: "State",
    letsTakeAPhoto: "Let's take a picture of you to identify you, therefore",
    removeAccessories: "Take off your glasses, hat and try not to smile",
    frameFace: "Frame your face in the center of the screen",
    hostingData: "Hosting Data",
    hostDate: "Your stay is of the day",
    dateTo: "to",
    apartmentIs: "Apartment number is",
    roomNumber: "Room number",
    numberNight: "Number of nights",
    keyConfig: "Configuring your Key",
    invoice: "Invoice",
    invoiceName: "Name",
    invoiceCompany: "Company",
    invoiceDoc: "Document",
    invoiceAddress: "Address",
    invoiceArrival: "Arrival",
    invoiceUH: "UH",
    invoiceReservation: "Reservation",
    invoiceAd: "Ad/Cr1/Cr2",
    invoiceEmission: "Emission",
    tableDate: "Date",
    tableDescription: "Description",
    tableUH: "UH",
    tableValueCredit: "Credit",
    tableValueDebit: "Debit",
    tableValue: "Value",
    tableTotal: "Total",
    btnAgree: "I Agree",
    btnDisagree: "I Disagree",
    invoiceTerms:
      'By clicking "I agree", you declare that you agree to the charges that will be automatically charged to your credit card.',
    checkoutPrice: "Checkout Price",
    name: "Name",
    wasPreCheckinMade: "Pre check-in performed",
    scheduledCheckin: "Scheduled Check-in",
    checkinMade: "Check-in performed",
    scheduledCheckout: "Scheduled Check-out",
    checkoutMade: "Check-out performed",
    reservationStatus: "Reservation status",
    phone: "Phone number",
    birthDate: "Birth Date",
    dashboard: "Dashboard",
    reservationList: "Reservations",
    guestList: "Guests",
    guestRegistrationInfo: "Guest Registration",
    settings: "Settings",
    reservation: "Reservation",
    youCanMakeCheckouts: "You can charge and check out the reservation",
    reservationDetails: "Reservation details",
    charge: "Charge",
    makeCheckout: "Perform check-out",
    sendCharge: "Send Charge",
    confirm: "Confirm",
    cancel: "Cancel",
    makeCheckoutMessage: "You will check out {person} for",
    changeValue: "Change value",
    hello: "Hello",
    startPreCheckin: "Start Pre Checkin",
    registerGuestPreCheckin:
      "To proceed with the pre-checkin, you need to register one of the following people.",
    selfiePreCheckin: "Take a selfie with your cellphone.",
    selfiePreCheckinDesk: "Take a photo with your cellphone or choose one.",
    documentPic: "Take a picture of your document with your cell phone.",
    removePlastic: "Don't forget to remove the document from the plastic.",
    passport: "Passport",
    takePhoto: "Take Photo",
    countryVisited: "Which country / countries did you visit ?",
    tripPeriod: "What was the period of the trip ?",
    arrivalBrazil: "Date of arrival in Brazil",
    when: "When ?",
    main: "Main",
    errorWebcam: "Something wrong with your webcam!",
    pet: "Do you intend to take your pet?",
    preCheckinQuantity: "Pre checkins performed",
    checkinsMade: "Checkins performed",
    pendingCheckouts: "Pending Checkouts",
    checkoutsMade: "Checkouts performed",
    registeredGuests: "Registered Guests",
    registerCard: "Register Credit Card",
    cardSafe:
      "Your credit card is safe! It will only be used for any additional charges for your stay. We will check your credit card with a transaction but rest assured, we will return the amount in the same instant.",
    safeEnvironment: "You are in a secure environment",
    cleanBtn: "Clean",
    cardNumber: "Card Number",
    cardOwner: "Cardholder Name",
    cardValidity: "Validity",
    cardCVV: "CVV",
    error: "Error !",
    fixSettings: "Fix the settings",
    yesContinue: "Yes, continue",
    isNotMe: "Its not me",
    documentRegistered: "Registered document number",
    wannaCheckout: "Hello, would you like to checkout?",
    dontAgree: "Please go to the reception desk to finish.",
    other: "Other",
    registeredCard: "Do you want to pay with the card already registered?",
    invoicePayment: "Invoice Payment",
    chooseFile: "Choose photo",
    precheckinSuccess: "Success!",
    seeyousoon: "Your data has been received. We hope to see you soon",
    beforeStart: "Before you start, what would you like to do?",
    keyValidation: "Card validation",
    keepCard: "Please leave your card in the reader and then click 'OK'",
    requiredField: "* Required fields.",
    checkoutModalTitle: "We are almost there!",
    serverUrlSettings: "Key server URL",
    serverUrlSettingsPLaceholder: "Example: https://dormakaba.myhotel.com",
    selectBookingModal: "Select your reservation.",
    realizingCheckin: "Realizing your checkin, please wait.",
    realizingCheckout: "Realizing your checkout, please wait.",
    magnetizeOnTotem: "Magnetize key on totem",
    tablePaid: "Paid",
    totalToPay: "Total to be paid",
    processingPayment: "Processing your payment, please wait.",
    signature: "Signature",
    takeDocumentPicture: "Let's take a picture of your document, therefore",
    frameDoc: "Frame your document in the center of the screen",
    faceSide: "Show the side that has your picture",
    selectGuestTitle: "Select guest",
    returnKeys: "Please deposit your keys.",
    rules: "Rules",
    password: "Password",
    login: "Login",
    adminTitle: "Administrative Access",
    guestListTitle: "Guest list",
    guestSearchPlaceholder: "Search by name, document, phone, email and birthdate...",
    guestInfoSearchPlaceholder: "Search for Selected Period, Download Link and Download Date...",
    reservationListTitle: "Reservation list",
    reservationSearchPlaceholder: "Search by nome and reservation number...",
    optionalSettingsPetOption: "Use pet field on pre check-in?",
    optionalSettingsCovidOption: "Use COVID form?",
    optionalSettingsPaymentByGuest: "Payment by Guest?",
    optionalSettingsPayOption: "Use payment on check-in?",
    optionalSettingsSignatureOption: "Use signature?",
    optionalSettingsDocumentOption: "Use document picture?",
    preCheckinWelcome: "We want you to make the most of your stay in",
    anotherPicture: "Take another one",
    pictureClear: "The photo looks good?",
    cameraNotFound: "Camera not found.",
    chooseDocument: "Select a document to send the picture",
    yourHotel: "your hotel",
    keyRecord: "Key Registration",
    keyRecordInstructions:
      "To record your room key, please bring the card to the place marked on the totem",
    keyInstructions:
      'Please, get your keys on the demarcated location and place them on the reader, then click on "Next"',
    keyInstructionsStripe: 'Please, get your keys on the demarcated location, then click on "Next"',
    registerDate: "Register date",
    download: "Download",
    info: "Information",
    send: "Send",
    sendPrecheckin: "Send Pre-checkin",
    dateFormat: "DD/MM/YYYY",
    country: "Country",
    documentType: "Document Type",
    skipMagnetize:
      "Your checkin was registered successfully, please go to the reception to get your keys.",
    magnecticStripe: "Magnetic stripe",
    keyTech: "Key type",
    paymentStatus: "Payment status",
    cardFlag: "Card flag",
    cardDigits: "Card's last 4 digits",
    cards: "Cards",
    terms: "Terms and Use Conditions",
    privacy: "Privacy Policy",
    readAndAgree: "Read and accepeted the",
    collect: "Charge",
    history: "Expense billing history",
    newExpense: "Description of new expense",
    confirmCharge: "Confirm Billing",
    expense: "Expense",
    registerNewCard: "I want to register a new card",
    registerHint:
      "By pressing the button above, the old card will be immediately replaced by the new one.",
    installment: "Cuotas",
    selectedPeriod: "Selected Period",
    downloadLink: "Download Link",
    downloadDate: "Creation Date",
    crewListTitle: "Crew member registration",
    crewRegistrationInfo: "Crew",
    selectedDay: "Selected Day",
    downloadCrewLabel: "Download the registration form",
    btnDownloadFnhr: "Download FNRH",
    day: "Day",
    crewOk: "Confirmar",
    crewReturn: "It's not me"
  },
  alert: {
    fillInput: "The field is required!",
    fillInputName: "Type at least two characters.",
    fillRequired: "Complete all required fields!",
    reserveNotFound: "Reservation not found with this name!",
    reserveNotFoundSub: "Please try again or go to the counter to continue.",
    errorPayment:
      "Your card issuer did not approve your purchase. Please try again or go to the reception.",
    validDate: "Please insert a valid date.",
    validEmail: "Please insert a valid email.",
    validLastName: "Please insert your lastname.",
    cepNotFound: "Invalid zip code, try again.",
    checkoutSuccess: "Successful checkout",
    selectADevice: "Please select a device.",
    cantCheckin: "Unauthorized checkin",
    toDoCheckin:
      "Checkin by the totem can only be done after {time}, if you want to checkin, go to the reception",
    noBookingId: "No booking found with your name/document",
    defaultError: "Something went wrong.",
    invoiceError: "Prices of your hosting.",
    cardError: "An error occurred while registering your card, please try again.",
    tryAgain: "Something went wrong, please try again.",
    iddle: "Is someone there?",
    noBooking: "Reservation not found.",
    noRoom: "We are preparing your room. Please go to reception.",
    sendPrecheckin: "Are you sure that you want to send the Pre-checkin e-mail for all guests?",
    noBookingTryAgain: "Ops! Please try again or go to the counter.",
    noBookingTryAgainName:
      "Reservation with the name {name} not found, please try again or go to the counter.",
    noBookingTryAgainCheckout:
      "Reservation for the date {checkout} not found, please try again or go to the counter.",
    bookingRegisterError: "We couldn't recover your reservation data, please go to the counter.",
    checkinConfirmation:
      "Reservation identified, now we are going to confirm your information to continue.",
    magnetizeFail:
      "Your checkin was successful, but it was not possible to magnetize the key. Please go to the front desk to record a new card.",
    invalidFields: "Complete the fields correctly.",
    hasChild: "We have identified a child on your booking, please go to reception",
    notEnabledForTotem:
      "This reservation is not enabled for Check In and Check Out by Totem. Please go to reception",
    chargeExtra: "Are you sure you want to charge {amount} for consumption of {description}?",
    hadExtraExpenses: "Please go to the reception to complete the Check Out",
    signatureRequiredCheckin:
      "Please register your signature to validate that you agree with the data in your registration.",
    signatureRequiredCheckout:
      "Please register your signature to confirm that you agree with the values on the invoice.",
    downloadFNRH: "The forms are being processed and will soon appear in the download list.",
    crewHello: "Hello crew member"
  },
  keyboard: {
    space: "SPACE",
    enter: "NEXT",
    bksp: "BACKSPACE",
    ok: "OK"
  },
  validation: {
    required: "This field is required",
    email: "This field must be a valid email",
    "min-tomorrow": "The date must be after today",
    "min-length": (_, { length }) => `This field must have at least ${length} characters`,
    date: "This field must be a valid date",
    fullName: "Fill in your first and last name",
    cpf: "This field must be a valid CPF",
    terms: "Agree with our terms to proceed",
    vPhoneNumber: "invalid phone number."
  },
  booking_configuration: {
    hostType: "Tipo de anfitrión",
    client: "Cliente",
    origin: "Origen",
    segment: "Segmento",
    vehicle: "Vehículo",
    canDoCheckin: "¿Puedes registrarte?",
    canDoCheckout: "¿Puedes comprobarlo?",
    paymentOfPerDiems: "Pago de viáticos",
    documentPhoto: "Foto del documento",
    facialRecognition: "Reconocimiento facial",
    sendSMS: "enviar SMS",
    createRule: "Crear regla",
    editRule: "Editar regla",
    cancel: "Cancelar",
    delete: "Borrar",
    askToDelete: "¿Estás seguro de esto?",
    createdMessage: "¡Regla creada con éxito!",
    deletedMessage: "¡Regla eliminada con éxito!",
    updatedMessage: "¡Regla editada con éxito!",
    errorMessage: "Error creating/editing rule!",
    fieldsErrorMessage: "Fill in the fields correctly"
  }
};
