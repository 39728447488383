export default {
  message: {
    slug: "Informe o Slug",
    apikey: "Informe a API key",
    faceCameraNav: "Câmera Facial",
    documentCameraNav: "Câmera Documento",
    hotelConfigNav: "Configurações",
    languageNav: "Linguagem",
    faceCameraTitle: "Configurações Câmera Facial",
    documentCameraTitle: "Configurações Câmera Documento",
    hotelConfigTitle: "Configurações Hotel",
    blockCheckin: "Bloquear Check-in por horário",
    blockCheckout: "Bloquear Check-out por horário",
    doFacialRecognition: "Fazer reconhecimento facial?",
    smsEnabled: "Habilitar envio de SMS?",
    magnetizeSystem: "Escolha o sistema de magnetização",
    encoderAddress: "Endereço EncoderPMS",
    select: "Selecionar",
    save: "Salvar",
    portuguese: "Português",
    english: "Inglês",
    spanish: "Espanhol",
    systemLangugeTitle: "Linguagem do sistema",
    languagePlaceHolder: "Escolha um idioma",
    successSave: "Salvo com sucesso!",
    sameLanguage: "Este idioma já está selecionado",
    selectAnOption: "Selecione uma opção",
    selectKey: "Selecione uma chave",
    selectCheckin: "Selecione o seu check-in.",
    fixedCheckinMessage: "Check-in a partir das 14:00",
    variableCheckinMessage: "Check-in a partir das {time}",
    variableCheckoutMessage: "Check-out até às {time}",
    start: "Começar",
    doCheckinCheckout: "Faça seu Check-in ou Check-out aqui",
    cpfService: "Serviço disponível para reserva já cadastrada no Hotel",
    back: "Voltar",
    next: "Avançar",
    continue: "Continuar",
    guestName: "Nome",
    reservationNumber: "Nº da reserva",
    apartmentType: "Apartamento",
    apartmentNumber: "Nº do apartamento",
    enjoyYourStay: "Aproveite sua estadia",
    welcomeCaps: "SEJA BEM-VINDO",
    thankYouCaps: "OBRIGADO",
    seeYouSoon: "Volte sempre",
    reportName: "Informe o nome e sobrenome para identificarmos sua reserva",
    exit: "Sair",
    doCheckout: "Realizar Check-out",
    okCheckout: "Check Out Concluído. Até logo!",
    doCheckin: "Realizar Check-in",
    reportDocument: "Informe o número para localização",
    checkoutDate: "Informe a sua data de check-out",
    yes: "Sim",
    no: "Não",
    formCorona: "Formulário de Pesquisa Coronavirus",
    recentTravel: "Histórico de viagem internacional recente (últimos 14 dias)?",
    personCovid:
      "Você teve contato com uma pessoa com suspeita ou diagnosticada com o novo coronavirus (COVID-19)?",
    symptomsCovid: "Você teve algum(ns) dos seguintes sintomas?",
    symptomFever: "Febre",
    symptomThroat: "Dor de garganta",
    symptomCough: "Tosse",
    symptomBreathe: "Falta de ar",
    symptomMuscle: "Dores musculares",
    symptomDiarrhea: "Diarreia",
    finish: "Finalizar",
    selectGuest: "Caro hóspede, gostaria de realizar o check-in dos demais hóspedes?",
    fullName: "Nome Completo",
    birth: "Data de nascimento",
    genre: "Gênero",
    male: "Masculino",
    female: "Feminino",
    neutral: "Outros",
    cpf: "CPF",
    celNumber: "Número do celular",
    email: "E-mail",
    confirmDetails: "Preencha seus dados",
    cep: "CEP",
    address: "Endereço",
    addressNumber: "Número",
    addressComplement: "Complemento",
    neighborhood: "Bairro",
    city: "Cidade",
    state: "Estado",
    letsTakeAPhoto: "Vamos tirar uma foto sua para te identificar, portanto",
    removeAccessories: "Tire seus óculos, chapéu, máscara e tente não sorrir",
    frameFace: "Enquadre o seu rosto no centro da tela",
    hostingData: "Dados da Hospedagem",
    hostDate: "Sua hospedagem é do dia",
    dateTo: "até",
    apartmentIs: "Número do apartamento é",
    roomNumber: "Número do quarto",
    numberNight: "Número de noites",
    keyConfig: "Configurando sua Chave",
    invoice: "Fatura",
    invoiceName: "Nome",
    invoiceCompany: "Empresa",
    invoiceDoc: "Documento",
    invoiceAddress: "Endereço",
    invoiceArrival: "Chegada",
    invoiceUH: "UH",
    invoiceReservation: "Reserva",
    invoiceAd: "Ad/Cr1/Cr2",
    invoiceEmission: "Emissão",
    tableDate: "Data",
    tableDescription: "Descrição",
    tableUH: "UH",
    tableValueCredit: "Crédito",
    tableValueDebit: "Débito",
    tableValue: "Valor",
    tableTotal: "Total",
    btnAgree: "Concordo",
    btnDisagree: "Não concordo",
    invoiceTerms:
      'Ao clicar em "Concordo", você declara estar de acordo com as cobranças que serão automaticamente debitadas em seu cartão de crédito.',
    name: "Nome",
    wasPreCheckinMade: "Pré check-in realizado",
    scheduledCheckin: "Check-in programado",
    checkinMade: "Check-in realizado",
    scheduledCheckout: "Check-out programado",
    checkoutMade: "Check-out realizado",
    reservationStatus: "Status da reserva",
    phone: "Telefone",
    birthDate: "Data de nascimento",
    dashboard: "Dashboard",
    reservationList: "Reservas",
    guestList: "Hóspedes",
    guestRegistrationInfo: "Ficha do Hóspede",
    rules: "Regras",
    crewRegistrationInfo: "Tripulação",
    settings: "Configurações",
    checkoutPrice: "Preço Check-out",
    reservation: "Reserva",
    youCanMakeCheckouts: "Você pode realizar cobranças e o check-out da reserva",
    reservationDetails: "Detalhes da reserva",
    charge: "Cobrança",
    makeCheckout: "Realizar check-out",
    sendCharge: "Emitir cobrança",
    confirm: "Confirmar",
    cancel: "Cancelar",
    makeCheckoutMessage: "Você fará o check-out de {person} no valor de",
    changeValue: "Alterar valor",
    hello: "Olá",
    startPreCheckin: "Iniciar Pré Check-in",
    registerGuestPreCheckin:
      "Para prosseguir com o pré check-in, você precisa cadastrar uma das seguintes pessoas.",
    selfiePreCheckin: "Tire uma foto com seu celular.",
    selfiePreCheckinDesk: "Tire uma foto com sua webcam ou escolha uma.",
    documentPic: "Tire uma foto do seu documento com o seu celular.",
    removePlastic: "Não esqueça de tirar o documento do plástico.",
    passport: "Passaporte",
    takePhoto: "Tirar Foto",
    countryVisited: "Que país/países visitou ?",
    tripPeriod: "Qual foi o período da viagem ?",
    arrivalBrazil: "Data de chegada ao Brasil",
    when: "Quando ?",
    main: "Principal",
    errorWebcam: "Algo deu errado com sua webcam!",
    pet: "Você pretende levar o seu animal de estimação?",
    preCheckinQuantity: "Pré check-ins realizados",
    checkinsMade: "Check-ins realizados",
    pendingCheckouts: "Check-outs pendentes",
    checkoutsMade: "Check-outs realizados",
    registeredGuests: "Hóspedes Cadastrados",
    registerCard: "Cadastrar Cartão de Crédito",
    cardSafe:
      "Seu cartão de crédito está seguro! Ele somente servirá para eventuais cobranças de gastos adicionais em sua estadía. Iremos verificar o seu cartão de crédito com uma transação mas fique tranquilo, vamos retornar o valor no mesmo instante.",
    safeEnvironment: "Você está em um ambiente seguro",
    cleanBtn: "Limpar",
    cardNumber: "Número do Cartão",
    cardOwner: "Nome do Titular",
    cardValidity: "Validade",
    cardCVV: "CVV",
    error: "Erro !",
    fixSettings: "Arrume as configurações",
    yesContinue: "Sim, continuar",
    isNotMe: "Não sou eu",
    documentRegistered: "Número do documento cadastrado",
    wannaCheckout: "Olá, gostaria de realizar o check-out?",
    dontAgree: "Por favor, dirija-se ao balcão da recepção para finalizar.",
    other: "Outro",
    registeredCard: "Deseja pagar com o cartão já cadastrado?",
    invoicePayment: "Pagamento da Fatura",
    chooseFile: "Escolher foto",
    precheckinSuccess: "Sucesso!",
    seeyousoon: "Seus dados foram recebidos. Esperamos você em breve",
    beforeStart: "Antes de começar, o que gostaria de fazer?",
    keyValidation: "Validação do cartão",
    keepCard: "Por favor, deixe o seu cartão no leitor e depois clique em 'OK'",
    requiredField: "*Campos obrigatórios.",
    checkoutModalTitle: "Estamos quase lá!",
    serverUrlSettings: "URL do servidor da chave",
    serverUrlSettingsPLaceholder: "Exemplo: https://dormakaba.meuhotel.com",
    selectBookingModal: "Selecione sua reserva.",
    realizingCheckin: "Realizando seu check-in, por favor aguarde.",
    realizingCheckout: "Realizando seu check-out, por favor aguarde.",
    magnetizeOnTotem: "Magnetizar chave no totem",
    tablePaid: "Pago",
    totalToPay: "Total a ser pago",
    processingPayment: "Processando seu pagamento, por favor aguarde.",
    signature: "Assinatura",
    takeDocumentPicture: "Vamos tirar uma foto do seu documento, portanto",
    frameDoc: "Enquadre o seu documento no centro da tela",
    faceSide: "Mostre o lado que tem uma foto sua",
    selectGuestTitle: "Selecionar hóspede",
    returnKeys: "Por favor, deposite suas chaves.",
    password: "Senha",
    login: "Login",
    adminTitle: "Acesso Administrativo",
    guestListTitle: "Lista de hóspedes",
    crewListTitle: "Registro dos membros da tripulação",
    guestSearchPlaceholder: "Pesquisar por nome, CPF, telefone, email e data de nascimento...",
    guestInfoSearchPlaceholder:
      "Pesquisar por Período Selecionado, Link para Download e Data de Download...",
    reservationListTitle: "Lista de reservas",
    reservationSearchPlaceholder: "Pesquisar por nome e número da reserva...",
    optionalSettingsPetOption: "Incluir campo de pet no pré check-in?",
    optionalSettingsCovidOption: "Incluir questionário COVID?",
    optionalSettingsPaymentByGuest: "Realizar o pagamento por hóspede?",
    optionalSettingsPayOption: "Incluir pagamento no check-in?",
    optionalSettingsSignatureOption: "Incluir assinatura?",
    optionalSettingsDocumentOption: "Incluir foto do documento?",
    preCheckinWelcome: "Queremos que você desfrute ao máximo sua estadia em",
    anotherPicture: "Tirar outra",
    pictureClear: "A foto ficou nítida ?",
    cameraNotFound: "Câmera não encontrada.",
    chooseDocument: "Escolha um documento para enviar uma foto",
    yourHotel: "seu hotel",
    keyRecord: "Gravação da chave",
    keyRecordInstructions:
      "Para gravar a chave do seu quarto, por favor aproxime o cartão ao local demarcado no totem.",
    keyInstructions:
      'Por favor, Retire a chave no local demarcado e depois deixe a chave no leitor e clique em "Avançar"',
    keyInstructionsStripe:
      'Por favor, Retire a chave no local demarcado e depois clique em "Avançar"',
    registerDate: "Data de cadastro",
    download: "Download",
    info: "Informação",
    send: "Enviar",
    sendPrecheckin: "Enviar Pré-checkin",
    dateFormat: "DD/MM/AAAA",
    country: "País",
    documentType: "Tipo de documento",
    registrationNumber: "Número Matrícula",
    skipMagnetize:
      "Seu checkin foi realizado com sucesso, por favor dirija-se a recepção para receber sua chave.",
    magnecticStripe: "Tarja magnetica",
    keyTech: "Tecnologia da chave",
    paymentStatus: "Status do pagamento",
    cardFlag: "Bandeira do cartão",
    cardDigits: "Últimos 4 digítos do cartão",
    cards: "Cartões",
    terms: "Termos e Condições de Uso",
    lgpd: "Todas as informações protegidas de acordo com a lei LGPD",
    privacy: "Política de Privacidade",
    readAndAgree: "Li e aceito os",
    amount: "Diária",
    collect: "Cobrar",
    history: "Histórico de cobrança de despesas",
    newExpense: "Descrição de nova despesa",
    confirmCharge: "Confirmar Cobrança",
    expense: "Despesa",
    registerNewCard: "Quero cadastrar um novo cartão",
    registerHint:
      "Ao apertar o botão acima o cartão antigo será substituído imediatamente pelo novo.",
    installment: "Parcelas",
    scanDocumentFront: "Aproxime a frente do seu documento no leitor",
    scanDocumentBack: "Aproxime o verso do seu documento no leitor",
    clickCapture:
      "Clique no botão de captura quando seu documento estiver posicionado corretamente.",
    selectedPeriod: "Período Selecionado",
    selectedDay: "Dia Selecionado",
    downloadLink: "Link para Download",
    downloadDate: "Data de Criação",
    downloadCrewLabel: "Download da ficha de registro",
    btnDownloadFnhr: "Baixar FNRH",
    day: "Dia",
    crewOk: "Confirmar",
    crewReturn: "Não sou eu"
  },
  alert: {
    fillInput: "Preencha o campo!",
    fillInputName: "Informe pelo menos dois caracteres.",
    fillRequired: "Preencha todos os campos obrigatórios!",
    reserveNotFound: "Reserva não encontrada com este nome!",
    reserveNotFoundSub: "Por favor tente novamente ou vá para a recepção para continuar.",
    errorPayment:
      "Sua administradora de cartão não aprovou a sua compra. Por favor, tente novamente ou dirija-se ao balcão.",
    validDate: "Por favor inserir uma data válida.",
    validEmail: "Por favor inserir um email válido.",
    validLastName: "Por favor inserir seu sobrenome.",
    cepNotFound: "CEP incorreto, digite novamente.",
    checkoutSuccess: "Sucesso ao finalizar o check-out",
    selectADevice: "Por favor, escolha um dispositivo.",
    cantCheckin: "Check-in não autorizado",
    toDoCheckin:
      "O check-in pelo totem só pode ser feito depois das {time} horas, caso queira fazer o check-in, se direcione ao balcão",
    noBookingId: "Não foi encontrada nenhuma reserva para seu nome / documento.",
    defaultError: "Algo deu errado.",
    invoiceError: "Valores da sua hospedagem.",
    cardError: "Erro ao cadastrar cartão, tente novamente.",
    tryAgain: "Algo deu errado, por favor, tente novamente.",
    iddle: "Tem alguém ai?",
    noRoom: "Estamos preparando o seu quarto. Por favor, dirija-se ao balcão.",
    noBooking: "Reserva não encontrada.",
    sendPrecheckin: "Tem certeza que deseja enviar o e-mail de Pré-checkin para todos os hóspedes?",
    noBookingTryAgain: "Ops! Por favor, tente novamente ou vá para o balcão.",
    noBookingTryAgainName:
      "Reserva não encontrada para o nome {name}, por favor, tente novamente ou vá para o balcão.",
    noBookingTryAgainCheckout:
      "Reserva não encontrada para a data {checkout}, por favor, tente novamente ou vá para o balcão.",
    bookingRegisterError:
      "Não foi possível recuperar os dados da sua reserva, por favor, dirija-se ao balcão.",
    checkinConfirmation:
      "Sua reserva foi identificada com sucesso, agora vamos confirmar algumas informações para prosseguir.",
    magnetizeFail:
      "Seu checkin foi realizado com sucesso, mas não foi possível magnetizar a chave. Por favor, dirija-se a recepção para gravar um novo cartão.",
    invalidFields: "Preencha os campos corretamente.",
    hasChild: "Identificamos uma criança na sua reserva, por favor vá para recepção",
    notEnabledForTotem:
      "Esta reserva não está habilitada para Check In e Check Out pelo Totem. Por favor, dirija-se a recepção",
    chargeExtra:
      "Está certo que deseja efetuar a cobrança de {amount} por consumo de {description}",
    hadExtraExpenses: "Por favor, dirija-se a recepção para concluir o Check Out",
    signatureRequiredCheckin:
      "Por favor, registre sua assinatura para validar que concorda com os dados do seu cadastro.",
    signatureRequiredCheckout:
      "Por favor, registre sua assinatura para confirmar que concorda com os valores da fatura.",
    downloadFNRH:
      "As fichas estão sendo processadas e em breve aparecerão na listagem para download",
    crewHello: "Olá, Tripulante"
  },
  keyboard: {
    space: "ESPAÇO",
    enter: "AVANÇAR",
    bksp: "APAGAR",
    ok: "OK"
  },
  validation: {
    required: "Esse campo é obrigatório",
    email: "Esse campo deve ser um e-mail válido",
    "min-tomorrow": "A data deve ser maior do que o dia de hoje",
    "min-length": `Esse campo deve ter pelo menos {length} dígitos`,
    date: "Esse campo deve ser uma data válida",
    fullName: "Insira seu nome e sobrenome",
    cpf: "Esse campo deve ser um CPF válido",
    terms: "É preciso aceitar os termos para prosseguir",
    vPhoneNumber: "Número telefone inválido."
  },
  booking_configuration: {
    hostType: "Tipo de Hospede",
    client: "Cliente",
    origin: "Origem",
    segment: "Segmento",
    vehicle: "Veículo",
    canDoCheckin: "Pode fazer checkin?",
    canDoCheckout: "Pode fazer checkout?",
    paymentOfPerDiems: "Pagamento das Diárias",
    documentPhoto: "Foto do Documento",
    facialRecognition: "Reconhecimento Facial",
    sendSMS: "Enviar SMS",
    createRule: "Criar Regra",
    editRule: "Editar Regra",
    cancel: "Cancelar",
    delete: "Excluir",
    askToDelete: "Você tem certeza disso?",
    createdMessage: "Regra criada com sucesso!",
    deletedMessage: "Regra deletada com sucesso!",
    updatedMessage: "Regra editada com sucesso!",
    errorMessage: "Erro ao criar regra",
    fieldsErrorMessage: "Preencha corretamente os campos"
  }
};
