<template>
    <b-card bg-variant="dark" class="container">
      <h2>{{$t('message.downloadCrewLabel')}}</h2>
      <div class="row px-15 date-filters-row mt-4">
        <div class="options">
            <strong>{{$t('message.day')}}</strong>
            <b-form-datepicker
              v-model="selectedDate"
              locale="pt-BR"
              :hide-header="true"
              placeholder="Data"
              size="lg"
              label-help=""
              selected-variant="warning"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            ></b-form-datepicker>    
            <b-button class="btnfnrh" variant="primary" @click="handleDownload">
              {{$t('message.btnDownloadFnhr')}}
            </b-button>
        </div>
      </div>
    </b-card>
  </template>
  
  <script>
  import moment from "moment";
  import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
  
  export default {
    name: "CrewRecordDownload",
    components: {

    },
    data() {
      return {
        selectedDate: null,
        localeData: {
          direction: "ltr",
          format: "dd/mm/yyyy",
          separator: " - ",
          applyLabel: "Confirmar",
          cancelLabel: "Cancelar",
          weekLabel: "Semana",
          customRangeLabel: "Custom Range",
          daysOfWeek: ["D", "S", "T", "Q", "Q", "S", "S"],
          monthNames: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
          ],
          firstDay: 0
        }
      };
    },
    computed: {
        
    },
    filters: {
      date: function(value) {
        if (!value) return "";
  
        return moment(value).format("DD/MM/YYYY");
      }
    },
    methods: {
      async handleDownload() {
        var dateStart = this.selectedDate;

        var params = {
          start: dateStart == null ? null : moment(dateStart).format("YYYY/MM/DD"),
        };

        this.$emit("handleLoading", true);
  
        await this.$API.admin
          .generateCrewReservation(params)
          .then(() => {
            this.$toast.warning(this.$i18n.t("alert.downloadFNRH"));
          })
          .catch(() => {
            this.$swal.fire({
              title: "Não há dados disponíveis para o período selecionado",
              icon: "info",
              width: 500,
              showCloseButton: false,
              showCancelButton: false,
              focusConfirm: false,
              allowOutsideClick: true,
              confirmButtonText: `OK`,
              cancelButtonText: `cancel`,
              cancelButtonColor: "black",
              confirmButtonColor: "black",
              iconColor: "black",
              background: "#ffd400"
            });
          })
          .finally(() => {
            this.$emit("handleLoading", false);
          });
      },
      logEvent(name, date) {
        this.selectedDate = date;
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .container {
    margin-top: 20px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 40px;
  }
  h2 {
    color: $white;
  }
  button {
    margin: 0;
  }
  .options {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 20px;
  }
  .options strong {
    color: $white;
    font-size: 11px;
  }
  .vue-daterange-picker {
    flex: 1;
    min-width: 170px;
  }
  .btnfnrh{
    width: 190px;
    max-height: 31px;
  }
  </style>
  