<template>
  <svg
    :class="{ found: faceFound, 'not-found': faceFound === false }"
    class="maskFace show"
    width="1920px"
    height="1080px"
    viewBox="0 0 1920 1080"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Desktop">
        <g id="Group" transform="translate(-8.000000, -275.000000)">
          <path
            d="M1931,0.5 L1931,2078 L0.5,2078 L0.5,0.5 L1931,0.5 Z M967.711424,392.258812 C942.93901,389.846921 915.118289,393.101884 883.89935,403.212381 C877.569515,405.574087 868.792133,409.705803 857.786881,416.264789 C839.74552,427.017203 821.750933,440.897436 804.936982,458.289949 C746.382491,518.859279 716.969393,604.866052 731.546406,718.188999 C712.571854,729.237656 709.075257,758.201179 718.640662,802.644571 C723.903558,827.143761 730.88138,846.245931 739.428512,860.558958 C747.871248,874.697166 757.5125,883.69133 767.172256,888.410856 C768.704749,896.857437 771.118051,906.961557 774.683234,918.405914 C785.127108,951.931101 802.454549,985.915882 828.684922,1019.04431 C840.030521,1033.9025 854.980087,1049.76126 873.180459,1063.84357 C900.446434,1084.94028 930.061606,1097.95122 961.586716,1099.36809 C1012.86042,1101.44042 1062.41053,1072.62782 1109.02359,1015.49535 C1109.66268,1014.84772 1110.27018,1014.14954 1110.84115,1013.40159 C1135.08431,981.64396 1151.42017,948.646581 1161.29239,916.293201 C1164.52401,905.702485 1166.75594,896.329518 1168.21293,888.439833 C1177.91499,883.717018 1187.60105,874.683904 1196.07847,860.461744 C1204.60205,846.162157 1211.56745,827.094944 1216.83225,802.647319 C1226.38496,758.206828 1222.87014,729.248779 1203.88725,718.197021 C1218.47916,604.865295 1189.07005,518.853231 1130.51286,458.282246 C1113.70105,440.892275 1095.70877,427.014028 1077.66972,416.263107 C1066.66586,409.705024 1057.88957,405.573847 1052.43172,403.517461 C1020.36351,393.122453 992.580411,389.876268 967.711424,392.258812 Z"
            id="Combined-Shape"
            fill-opacity="0.7"
            fill="#000000"
          ></path>
          <path
            class="onlyFace"
            d="M731.550789,717.311797 C718.251028,609.884794 744.326645,523.030245 805.181573,460.01928 C821.341312,443.287016 839.162586,429.209883 858.040423,417.947852 C868.156253,411.913008 877.123544,407.49204 884.368004,404.786417 L884.513228,404.735786 C914.896264,394.886305 942.796152,391.305488 968.272686,393.73332 C993.951035,391.325431 1021.8377,394.950798 1052.92361,405.037322 L1053.07849,405.091622 C1059.59303,407.548562 1068.61394,412.028865 1078.53287,417.946182 C1097.40833,429.206669 1115.22735,443.281806 1131.38505,460.01165 C1192.24306,523.024621 1218.3138,609.884735 1205.00018,717.319553 C1223.38018,729.784511 1227.01719,758.619181 1217.44004,803.217235 C1212.23864,827.394286 1205.28884,846.689178 1196.55271,861.359799 C1188.63822,874.650656 1179.30032,884.134547 1169.17762,889.532187 C1167.6205,897.585984 1165.41867,906.401673 1162.57825,915.719535 C1152.28267,949.493652 1135.50481,982.210259 1112.24514,1012.70958 C1111.63134,1013.51443 1110.97573,1014.27526 1110.28142,1014.98939 C1063.34003,1072.53438 1013.50009,1101.05061 962.070914,1098.96992 C931.319251,1097.58645 901.443208,1085.28803 872.875631,1063.16243 C855.882676,1050.00139 840.744918,1034.53954 828.380542,1018.33031 C803.252082,986.562296 785.421789,952.877089 774.51306,917.825192 C771.371142,907.729587 768.970969,898.183604 767.318724,889.497417 C757.245369,884.107006 747.952787,874.668814 740.071737,861.458199 C731.311066,846.77312 724.348771,827.44204 719.149273,803.214118 C709.558449,758.608704 713.180672,729.773623 731.550789,717.311797 Z"
            id="Shape"
            stroke="#FFFFFF"
            stroke-width="7"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "FaceMask",
  props: {
    faceFound: {
      default: null
    }
  }
};
</script>
<style lang="scss" scoped>
.maskFace {
  object-fit: cover;
  &.found {
    g {
      path:last-child {
        stroke: green;
      }
    }
  }
  &.not-found {
    g {
      path:last-child {
        stroke: red;
      }
    }
  }
}
</style>
