const bookingConfiguration = instance => ({
  listBookingConfigurations: data =>
    new Promise((resolve, reject) =>
      instance.$http
        .get("/booking-configuration", {
          params: data
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(error))
    ),
  createBookingConfiguration: data =>
    new Promise((resolve, reject) =>
      instance.$http
        .post("/booking-configuration", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => reject(error))
    ),
  updateBookingConfiguration: data =>
    new Promise((resolve, reject) =>
      instance.$http
        .put("/booking-configuration", data)
        .then(response => {
          resolve(response);
        })
        .catch(error => reject(error))
    ),
  deleteBookingConfiguration: id =>
    new Promise((resolve, reject) =>
      instance.$http
        .delete(`/booking-configuration?id=${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => reject(error))
    ),
  listGuestTypes: data =>
    new Promise((resolve, reject) =>
      instance.$http
        .get("/booking-configuration/guest-type", {
          params: data
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(error))
    ),
  listOrigins: data =>
    new Promise((resolve, reject) =>
      instance.$http
        .get("/booking-configuration/origins", {
          params: data
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(error))
    ),
  listSegments: data =>
    new Promise((resolve, reject) =>
      instance.$http
        .get("/booking-configuration/segments", {
          params: data
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(error))
    ),
  listMedias: data =>
    new Promise((resolve, reject) =>
      instance.$http
        .get("/booking-configuration/medias", {
          params: data
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(error))
    ),
  searchCustomer: string =>
    new Promise((resolve, reject) =>
      instance.$http
        .get(`/booking-configuration/search-customer?searchTerm=${string}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(error))
    ),
  getConfigPermission: id =>
    new Promise((resolve, reject) =>
      instance.$http
        .get(`/booking/config-permission?id=${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => reject(error))
    )
});

export default bookingConfiguration;
