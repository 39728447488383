import { render, staticRenderFns } from "./ReservationsPeriod.vue?vue&type=template&id=31ccdd34&scoped=true"
import script from "./ReservationsPeriod.vue?vue&type=script&lang=js"
export * from "./ReservationsPeriod.vue?vue&type=script&lang=js"
import style0 from "./ReservationsPeriod.vue?vue&type=style&index=0&id=31ccdd34&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31ccdd34",
  null
  
)

export default component.exports