export default {
  message: {
    slug: "Informe el Slug",
    apikey: "Introduce la clave API",
    faceCameraNav: "Cámara facial",
    documentCameraNav: "Cámara de documentos",
    hotelConfigNav: "Configuraciones",
    languageNav: "Idioma",
    faceCameraTitle: "Configuración de la cámara facial",
    documentCameraTitle: "Configuración de la cámara de documentos",
    hotelConfigTitle: "Configuración del hotel",
    blockCheckin: "Registro bloqueado por horario",
    blockCheckout: "Pago bloqueado por horario",
    doFacialRecognition: "¿Hacer reconocimiento facial?",
    smsEnabled: "¿Habilitar el envío de sms?",
    magnetizeSystem: "Elija el sistema de magnetización",
    encoderAddress: "Dirección PMS del codificador",
    select: "Seleccione",
    save: "Salvar",
    portuguese: "Portugués",
    english: "Inglés",
    spanish: "Español",
    systemLangugeTitle: "Lenguaje del sistema",
    languagePlaceHolder: "Elige un idioma",
    successSave: "¡Guardado exitosamente!",
    sameLanguage: "Este idioma ya está seleccionado",
    selectAnOption: "Seleccione una opción",
    selectCheckin: "Seleccione su checkin.",
    selectKey: "Seleccione una llave",
    fixedCheckinMessage: "Llegada a partir de las 14:00",
    variableCheckinMessage: "Llegada a partir de las {time}",
    variableCheckoutMessage: "Pago hasta las {time}",
    start: "Empezar",
    doCheckinCheckout: "Check-in o Check-out aquí",
    cpfService: "Servicio disponible para reserva ya registrada en el Hotel",
    back: "Vuelve",
    next: "Avanzar",
    continue: "Seguir",
    guestName: "Nombre",
    reservationNumber: "Nº de la reserva",
    apartmentType: "Apartmento",
    apartmentNumber: "Nº de apartamento",
    enjoyYourStay: "Disfruta tu estancia",
    welcomeCaps: "SEA BIENVENIDO",
    thankYouCaps: "OBRIGADO",
    seeYouSoon: "Vuelva siempre",
    reportName: "Ingrese el nombre y apellido para identificar su reserva",
    exit: "Salir",
    doCheckout: "Hacer el Check-out",
    okCheckout: "Pago completado. ¡Hasta luego!",
    doCheckin: "Hacer el Checkin",
    reportDocument: "Ingrese su número para localización",
    checkoutDate: "Ingrese su fecha de pago",
    yes: "Sí",
    no: "No",
    formCorona: "Formulario de Búsqueda Coronavirus",
    recentTravel: "Historia reciente de viajes internacionales (últimos 14 días)?",
    personCovid:
      "¿Ha tenido contacto con una persona sospechosa o diagnosticada con el nuevo virus? coronavirus (COVID-19)?",
    symptomsCovid: "¿Tiene alguno de los siguientes síntomas?",
    symptomFever: "Fiebre:",
    symptomThroat: "Dolor de garganta:",
    symptomCough: "Tos:",
    symptomBreathe: "Falta de aire:",
    symptomMuscle: "Dolores musculares:",
    symptomDiarrhea: "Diarrea:",
    finish: "Terminar",
    selectGuest: "Estimado huésped, ¿le gustaría hacer el check-in para otros huéspedes?",
    fullName: "Nombre Completo: ",
    birth: "Fecha de nacimiento: ",
    genre: "Género: ",
    male: "Masculino",
    female: "Femenino",
    neutral: "Otros",
    cpf: "CPF: ",
    celNumber: "Número de teléfono: ",
    email: "E-mail",
    confirmDetails: "Llena tus datos",
    cep: "Código Postal",
    address: "Dirección",
    addressNumber: "Número",
    addressComplement: "Complemento",
    neighborhood: "Barrio",
    city: "Ciudad",
    state: "Estado",
    letsTakeAPhoto: "Vamos a sacarle una foto para identificarle, por tanto:",
    removeAccessories: "Quítate las gafas, el sombrero y trata de no sonreír",
    frameFace: "Enmarca tu rostro en el centro de la pantalla",
    hostingData: "Datos de Alojamiento",
    hostDate: "Tu estancia es del dia",
    dateTo: "al",
    apartmentIs: "El número de apartamento es",
    roomNumber: "Número de habitación",
    numberNight: "Número de noches",
    keyConfig: "Configurando su Llave",
    invoice: "Factura",
    invoiceName: "Nombre:",
    invoiceCompany: "Empresa:",
    invoiceDoc: "Documento:",
    invoiceAddress: "Dirección:",
    invoiceArrival: "llegada:",
    invoiceUH: "UH:",
    invoiceReservation: "Reserva:",
    invoiceAd: "Ad/Cr1/Cr2:",
    invoiceEmission: "Emisión:",
    tableDate: "Fecha",
    tableDescription: "Descripción",
    tableUH: "UH",
    tableValueCredit: "Crédito",
    tableValueDebit: "Débito",
    tableValue: "Valor",
    tableTotal: "Total",
    btnAgree: "Estoy de acuerdo",
    btnDisagree: "Estoy en desacuerdo",
    invoiceTerms:
      'Al hacer clic en "Acuerdo", declara que acepta los cargos que se cargarán automáticamente a su tarjeta de crédito.',
    checkoutPrice: "Precio de caja",
    name: "Nombre",
    wasPreCheckinMade: "Pre check-in realizado",
    scheduledCheckin: "Check-in programado",
    checkinMade: "Check-in realizado",
    scheduledCheckout: "Check-out programado",
    checkoutMade: "Check-out realizado",
    reservationStatus: "Estado de la reserva",
    phone: "Teléfono",
    birthDate: "Fecha de nacimiento",
    dashboard: "Dashboard",
    reservationList: "Reservaciones",
    guestList: "Invitados",
    guestRegistrationInfo: "Tarjeta de Registro",
    rules: "Normas",
    settings: "Ajustes",
    reservation: "Reserva",
    youCanMakeCheckouts: "Puedes cobrar y consultar la reserva",
    reservationDetails: "Detalles de la reserva",
    charge: "Colección",
    makeCheckout: "Revisa",
    sendCharge: "Cargo de emisión",
    confirm: "Confirmar",
    cancel: "Cancelar",
    makeCheckoutMessage: "Comprobarás a {person} para",
    changeValue: "Valor de cambio",
    hello: "Hola",
    startPreCheckin: "Comenzar Pré Checkin",
    registerGuestPreCheckin:
      "Para continuar con el registro previo, debe registrar a una de las siguientes personas.",
    selfiePreCheckin: "Tómate una selfie con tu teléfono.",
    selfiePreCheckinDesk: "Tómate una foto con tu webcam o elige una.",
    documentPic: "Tome una foto de su documento con su teléfono celular.",
    removePlastic: "No olvide retirar el documento del plástico.",
    passport: "Pasaporte",
    takePhoto: "Tomar la foto",
    countryVisited: "¿Qué país / países visitaste?",
    tripPeriod: "¿Cuál fue el período del viaje?",
    arrivalBrazil: "Fecha de llegada a Brasil :",
    when: "¿Cuando?",
    main: "Principal",
    errorWebcam: "¡Algo salió mal con tu cámara web!",
    pet: "¿Piensas llevarte a tu mascota?",
    preCheckinQuantity: "Comprobaciones previas realizadas",
    checkinsMade: "Comprobaciones realizadas",
    pendingCheckouts: "Salidas pendientes",
    checkoutsMade: "Verificaciones realizadas",
    registeredGuests: "Invitados registrados",
    registerCard: "Registrar Tarjeta de Crédito",
    cardSafe:
      "¡Tu tarjeta de crédito está segura! Solo se utilizará para cualquier cargo adicional por su estadía. Comprobaremos su tarjeta de crédito con una transacción pero tenga la seguridad de que le devolveremos el importe en el mismo instante.",
    safeEnvironment: "Estás en un entorno seguro",
    cleanBtn: "Limpar",
    cardNumber: "Numero de Tarjeta",
    cardOwner: "Nombre del titular",
    cardValidity: "Validez",
    cardCVV: "CVV",
    error: "¡ Error !",
    fixSettings: "Corrija la configuración",
    yesContinue: "Si, continua",
    isNotMe: "No soy yo",
    documentRegistered: "Número de documento registrado",
    wannaCheckout: "Hola, ¿te gustaría pagar?",
    dontAgree: "Dirígete a la recepción para terminar.",
    other: "Otro",
    registeredCard: "¿Quieres pagar con la tarjeta ya registrada?",
    invoicePayment: "Pago de la factura",
    chooseFile: "Escoge una foto",
    precheckinSuccess: "¡Éxito!",
    seeyousoon: "Sus datos han sido recibidos. Esperamos verte pronto",
    beforeStart: "Antes de empezar, ¿qué le gustaría hacer?",
    keyValidation: "Validación de tarjeta",
    keepCard: "Deje su tarjeta en el lector y luego haga clic en 'OK'",
    requiredField: "* Campos obligatorios.",
    checkoutModalTitle: "¡Está casi acabado!",
    serverUrlSettings: "URL del servidor de la llave",
    serverUrlSettingsPLaceholder: "Ejemplo: https://dormakaba.mihotel.com",
    selectBookingModal: "Seleccione su reserva.",
    realizingCheckin: "Realizando su checkin, espera.",
    realizingCheckout: "Realizando el pago, espera.",
    magnetizeOnTotem: "Magnetizar llave junto al tótem",
    tablePaid: "Pagado",
    totalToPay: "Total a pagar",
    processingPayment: "Procesando su pago, espera.",
    signature: "Firma",
    takeDocumentPicture: "Vamos a sacar una foto de su documento, por tanto:",
    frameDoc: "Enmarca tu documento en el centro de la pantalla",
    faceSide: "Muestra el lado que tiene una foto tuya",
    selectGuestTitle: "Seleccionar huésped",
    returnKeys: "Deposita sus llaves.",
    password: "Contraseña",
    login: "Acceso",
    adminTitle: "Acceso Administrativo",
    guestListTitle: "Lista de huéspedes",
    guestSearchPlaceholder:
      "Búsqueda por nombre, documento, teléfono, email y fecha de nacimiento...",
    guestInfoSearchPlaceholder:
      "Buscar por Periodo Seleccionado, Enlace para Descargar y Fecha de Descarga...",
    reservationListTitle: "Lista de Reservaciones",
    reservationSearchPlaceholder: "Búsqueda por nombre y número de la reserva...",
    optionalSettingsPetOption: "¿Usar el campo de pet en el pre check-in?",
    optionalSettingsCovidOption: "¿Usar el formulario de Búsqueda Coronavirus",
    optionalSettingsPaymentByGuest: "¿Realizar el pago por huéspede?",
    optionalSettingsPayOption: "¿Realizar el pago en el check-in?",
    optionalSettingsSignatureOption: "¿Usar firma?",
    optionalSettingsDocumentOption: "¿Usar foto del documento?",
    preCheckinWelcome: "Queremos que aproveches su estadía al máximo en",
    anotherPicture: "Toma otra foto",
    pictureClear: "¿Se ve bien la foto?",
    cameraNotFound: "Cámara no encontrada.",
    chooseDocument: "Escoge un documento para enviar una foto:",
    yourHotel: "su hotel",
    keyRecord: "Grabación de la llave",
    keyRecordInstructions:
      "Para registrar la llave de su habitación, lleve la tarjeta al lugar marcado en el tótem",
    keyInstructions:
      'Toma la llave en el lugar indicado, despues déjala en el lector y haga clic en "Avanzar"',
    keyInstructionsStripe: 'Toma la llave en el lugar indicado, despues haga clic en "Avanzar"',
    registerDate: "Fecha de registro",
    download: "Descargar",
    info: "Información",
    send: "Enviar",
    sendPrecheckin: "Enviar Pré checkin",
    dateFormat: "DD/MM/AAAA",
    country: "País",
    documentType: "Tipo de documento",
    skipMagnetize: "Su checkin fue realizado, dirígete a la recepción para retirar las llaves.",
    magnecticStripe: "Franja magnética",
    keyTech: "Tecnología de la llave",
    paymentStatus: "Estatus de pago",
    cardFlag: "Bandera de la tarjeta",
    cardDigits: "Últimos 4 digitos de la tarjeta",
    cards: "Tarjetas",
    terms: "Términos y Condiciones de Uso",
    privacy: "Política de Privacidad",
    readAndAgree: "He leído y acepto los",
    collect: "Cobrar",
    history: "Historial de facturación de gastos",
    newExpense: "Descripción del nuevo gasto",
    confirmCharge: "Confirmar facturación",
    expense: "Gastos",
    registerNewCard: "Quiero registrar una nueva tarjeta",
    registerHint:
      "Al presionar el botón de arriba, la tarjeta antigua será reemplazada inmediatamente por la nueva.",
    installment: "Parcelas",
    selectedPeriod: "Periodo Seleccionado",
    downloadLink: "Enlace para Descargar",
    downloadDate: "Fecha de Creación",
    crewListTitle: "Registro de tripulantes",
    crewRegistrationInfo: "Multitud",
    selectedDay: "Día seleccionado",
    downloadCrewLabel: "Descarga el formulario de registro",
    btnDownloadFnhr: "Descargar FNRH",
    day: "Día",
    crewOk: "Confirmar",
    crewReturn: "No soy yo"
  },
  alert: {
    fillInput: "¡El campo es obligatorio!",
    fillInputName: "ingrese al menos dos caracteres.",
    fillRequired: "¡Complete todos los campos obligatorios!",
    reserveNotFound: "¡Reserva no encontrada con este nombre!",
    reserveNotFoundSub: "Vuelve a intentarlo o ve al mostrador para continuar.",
    errorPayment:
      "Su emisor de tarjetas no aprobó su compra. Por favor, intente de nuevo o diríjase al mostrador.",
    validDate: "Por favor introduzca una fecha valida.",
    validEmail: "Por favor introduzca un email valido.",
    validLastName: "Por favor introduzca su apellido.",
    cepNotFound: "Código postal no encontrado, vuelve a intentarlo.",
    checkoutSuccess: "Pago exitoso",
    selectADevice: "Elija un dispositivo.",
    cantCheckin: "Registro no autorizado",
    toDoCheckin:
      "El registro junto al tótem solo se puede realizar después de las {time}, si desea registrarse, ve al mostrador",
    noBookingId: "Ninguna reserva fue encontrada con su nombre/documento",
    defaultError: "Ocurrio un error",
    invoiceError: "Precios de tu hosting.",
    cardError: "Ocurrio un error al registrar tarjeta, vuelve a intentarlo.",
    tryAgain: "Ocurrio un error, vuelve a intentarlo.",
    iddle: "¿Hay alguien ahí?",
    noRoom: "Estamos preparando tu habitación. Por favor, diríjase al mostrador.",
    noBooking: "Reserva no encontrada",
    sendPrecheckin:
      "¿Está seguro de que quiere enviar el e-mail del Pré checkin para todos los huéspedes?",
    noBookingTryAgain: "�Ops! Por favor vuelve a intentarlo o dirígete a la recepción.",
    noBookingTryAgainName:
      "Reserva no econtrada con el nombre {name}, vuelve a intentarlo o dirígete a la recepción.",
    noBookingTryAgainCheckout:
      "Reserva no econtrada con la fecha {checkout}, vuelve a intentarlo o dirígete a la recepción.",
    bookingRegisterError: "No fue possible recuperar su reserva, dirígete a la recepción.",
    checkinConfirmation:
      "Su reserva fue encontrada, ahora vamos a confirmar algunas informaciones para continuar.",
    magnetizeFail:
      "Su checkin fue exitoso, pero no fue posible magnetizar la llave. Vaya a la recepción para registrar una nueva tarjeta.",
    invalidFields: "¡Completa los campos correctamente.",
    hasChild: "Hemos identificado a un niño en su reserva, por favor diríjase a recepción",
    notEnabledForTotem:
      "Esta reserva no está habilitada para Check In y Check Out por Totem. Por favor, diríjase a la recepción.",
    chargeExtra: "¿Está seguro de que desea cobrar {amount} por el consumo de {description}?",
    hadExtraExpenses: "Diríjase a la recepción para completar el Check Out",
    signatureRequiredCheckin:
      "Por favor, registra tu firma para validar que estás de acuerdo con los datos de tu registro.",
    signatureRequiredCheckout:
      "Por favor, registra tu firma para confirmar que estás de acuerdo con los valores en la factura.",
    downloadFNRH:
      "Los formularios se están procesando y pronto aparecerán en la lista de descargas.",
    crewHello: "Hola miembro de la tripulación"
  },
  keyboard: {
    space: "ESPACIO",
    enter: "SEGUIR",
    bksp: "ELIMINAR",
    ok: "OK"
  },
  validation: {
    required: "Este campo es obligatorio",
    email: "Este campo debe ser un email válido",
    "min-tomorrow": "La fecha debe ser mayor que la de hoy",
    "min-length": (_, { length }) => `Este campo tener por lo menos ${length} caracteres`,
    date: "Este campo debe ser una fecha válida",
    fullName: "Escriba su nombre y apellido",
    cpf: "Este campo debe ser un CPF válido",
    terms: "Acepta las condiciones para continuar",
    vPhoneNumber: "numero de telefono invalido."
  },
  booking_configuration: {
    hostType: "Host Type",
    client: "Client",
    origin: "Origin",
    segment: "Segment",
    vehicle: "Vehicle",
    canDoCheckin: "Can you check in?",
    canDoCheckout: "Can you checkout?",
    paymentOfPerDiems: "Payment of per diems",
    documentPhoto: "Document Photo",
    facialRecognition: "Facial recognition",
    sendSMS: "send SMS",
    createRule: "Create Rule",
    editRule: "Edit Rule",
    cancel: "Cancel",
    delete: "Delete",
    askToDelete: "Are you sure about this?",
    createdMessage: "Rule created successfully!",
    deletedMessage: "Rule deleted successfully!",
    updatedMessage: "Rule edited successfully!",
    errorMessage: "Error creating/editing rule!",
    fieldsErrorMessage: "Rellena los campos correctamente"
  }
};
