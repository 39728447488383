<template>
  <DocumentCapture
    :isLoading="isLoading"
    :videoPlayStatus="videoPlayStatus"
    @stream-connected="streamConnectedHandler"
    @start-processing="startProcessing"
    @finish-processing="submitDocumentHandler"
    @stop-loading="stopLoading"
  />
</template>

<script>
import DocumentCapture from "@/components/DocumentCapture.vue";

export default {
  name: "DocumentPage",
  components: {
    DocumentCapture
  },
  data() {
    return {
      isLoading: false,
      videoPlayStatus: false
    };
  },
  computed: {
    userId() {
      return this.$store.getters.getUserId;
    }
  },
  methods: {
    streamConnectedHandler() {
      this.videoPlayStatus = true;
    },
    startProcessing() {
      this.isLoading = true;
      this.videoPlayStatus = false;
    },
    stopLoading() {
      this.isLoading = false;
    },
    submitDocumentHandler(data) {
      this.$store.dispatch("SET_DOCUMENT_DATA", { value: data.img });
      this.documentSubmittedHandler(data.params);
    },
    documentSubmittedHandler(params) {
      this.$router.push({ name: "PersonalForm", params });
    }
  }
};
</script>
