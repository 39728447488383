<template>
  <b-modal id="terms-modal" hide-header hide-footer no-close-on-backdrop size="lg">
    <div class="WordSection1">
      <p class="MsoNormal" align="center" style="text-align:center">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >TERMOS E CONDIÇÕES DE USO DA YOUCHECK.IN</span
          ></b
        >
      </p>

      <p class="MsoListParagraph" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Introdução</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Este <b>Termo e Condições de Uso</b> rege a utilização pelos usuários da plataforma<b>
            WebApp</b
          >
          para realizar o prévio check-in e da plataforma <b>balcão de check-in self-service</b>,
          controlado e operado pela </span
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >YOUCHECK IN TECNOLOGIA LTDA</span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;
line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >, sociedade limitada, devidamente constituída e regida pelas leis da República Federativa
          do Brasil, com sede na Avenida Paulista, 1159, conjunto 1507, Bela Vista, CEP 01311-921,
          Cidade de São Paulo, Estado de São Paulo, aqui nominada como </span
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >youcheck.in</span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >O conteúdo deste Termo e Condições de Uso é direcionado para toda pessoa natural ou
          jurídica capaz, em pleno exercício de seus direitos e obrigações, de acordo com a lei
          brasileira e demais regulações internacionais aplicáveis.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >LEIA ATENTAMENTE OS TERMOS E CONDIÇÕES AQUI APRESENTADOS E OS DEMAIS AVISOS QUE POSSAM
          APARECER EM OUTRAS PÁGINAS DAS PLATAFORMAS DA YOUCHECK.IN. AO ACESSÁ-LAS, VOCÊ SOMENTE
          PROSSEGUIRÁ NA UTILIZAÇÃO, CASO ACEITE E CONCORDE COM OS PRESENTES TERMOS E CONDIÇÕES DE
          USO. CASO VOCÊ NÃO CONCORDE, AS PLATAFORMAS DA YOUCHECK.IN NEGARÃO O SEU ACESSO.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Boas Práticas</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Utilizamos a tecnologia para proporcionar uma experiência de auto atendimento agradável,
          ágil e com mínimo de burocracia na entrada e saída de locais acessíveis ao público. A
          <b>youcheck.in</b> acredita que o desenvolvimento econômico e tecnológico pautado na
          inovação deve respeitar a privacidade, a inviolabilidade da intimidade, da honra e da
          imagem e o respeito ao consumidor.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >A <b>Youcheck.in </b>se compromete a:</span
        >
      </p>

      <p class="MsoListParagraphCxSpFirst" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Manter atualizada todas as informações necessárias para a navegação nas
          plataformas;</span
        >
      </p>

      <p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Corrigir eventuais omissões ou imprecisões;</span
        >
      </p>

      <p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Dar tratamento aos dados fornecidos pelos usuários apenas com o expresso
          consentimento.</span
        >
      </p>

      <p class="MsoListParagraphCxSpMiddle" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >&nbsp;</span
          ></b
        >
      </p>

      <p class="MsoListParagraphCxSpLast" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Natureza dos serviços da youcheck.in</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Por meio da plataforma<b> WebApp</b> para realizar o prévio check-in e da plataforma
          <b>balcão de check-in self-service</b>, a <b>youcheck.in</b> se propõe a reduzir o tempo e
          facilitar o check-in e check-out dos hóspedes de hotéis, reduzindo o tempo na entrada e
          saída de locais acessíveis ao público.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Ao utilizar as plataformas da <b>youcheck.in</b>, os usuários celebram um contrato
          diretamente com um provedor de acomodação (hotel, locador etc.) que você efetuou o seu
          pedido de reserva de uma estadia. Com a realização da reserva de uma estadia, a
          <b>youcheck.in</b> age tão somente como intermediária entre o usuário das plataformas e o
          provedor de viagem ou de acomodação, transmitindo os dados relevantes da reserva de uma
          estadia ao provedor de acomodação contratado pelo usuário. A <b>youcheck.in </b>não vende,
          aluga ou presta quaisquer serviços de hotelaria ou similares.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Todo tráfego de informações realizadas tem como base as informações fornecidas pelo
          provedor de acomodação, exceto os dados tratados em nossas plataformas para realizar o
          check-in e o check-out dos usuários, que estão amparados por nossa política de privacidade
          prevista no <b><u>Termo de Privacidade</u></b> que o usuário aceita para utilizar os
          serviços da <b>youcheck.in</b>. Em razão disso, o provedor de acomodação é responsável por
          fornecer e atualizar as informações sobre preço, taxas, disponibilidade, horário,
          políticas e condições, entre outras informações sobre o pedido de reserva de estadia em
          seu estabelecimento. Na hipótese de quaisquer erros, interrupções, informações incorretas,
          falsas ou mesmo ausência de informações, a <b>youcheck.in</b> recomenda que o usuário das
          plataformas entre em contato diretamente com o provedor de acomodação.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Privacidade dos usuários</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >A <b>youcheck.in </b>possui uma política de privacidade dos dados dos usuários para uso
          das plataformas <b>WebApp</b> para realizar o prévio check-in e da plataforma
          <b>balcão de check-in self-service</b>.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Para saber mais sobre a política de privacidade, acesse o
          <b><u>Termo de Privacidade</u></b
          >, que integra parte deste <b>Termo e Condições de Uso</b> e deve ser lido atentamente
          antes da aceitação e utilização das plataformas.</span
        >
      </p>

      <p class="MsoListParagraph" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Propriedade intelectual</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif;
color:#333333'
          >Os softwares necessários para a prestação dos serviços pela </span
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >youcheck.in</span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >, disponível e utilizado nas plataformas do prévio check-in na<b> WebApp</b> e no
          <b>balcão de check-in self-service</b>, os direitos de propriedade intelectual de
          conteúdo, informação e material das plataformas são de propriedade da
          <b>youcheck.in</b>.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >A plataforma <b>WebApp</b> para realizar o prévio check-in, a plataforma
          <b>balcão de check-in self-service</b> e o website da <b>youcheck.in </b>contêm textos,
          fotografias, imagens, logomarcas e sons protegidos por direitos de propriedade
          intelectual, que pertencem à <b>youcheck.in</b>.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Ao acessar qualquer dessas plataformas, o usuário declara que respeitará os direitos de
          propriedade intelectual, decorrentes da marca registrada da <b>youcheck.in</b> e quaisquer
          outras marcas de terceiros.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Fica proibida a reprodução dos conteúdos das plataformas da <b>youcheck.in</b>, exceto
          quando autorizados.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >O uso indevido das informações contidas nas plataformas da <b>youcheck.in</b>, tais como,
          textos, artigos, fotografias, marcas,<b> </b>implicarão em responsabilidade civil e/ou
          criminal<b>.</b></span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Na hipótese de entender que algum direito esteja sendo infringido pelas informações
          contidas nas plataformas da <b>youcheck.in</b>, encaminhe uma mensagem com as razões e os
          motivos, com nome completo e telefone de contato para o email suporte@youcheck.in, para
          que seja possível apreciar a infração alegada e responder com brevidade.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Cadastro do usuário</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Para que o usuário possa desfrutar dos serviços de auto atendimento da
          <b>youcheck.in</b> será necessário acessar e realizar o seu prévio cadastro na plataforma
          <b>WebApp</b>. Com efeito, o usuário concorda em:</span
        >
      </p>

      <p class="MsoListParagraphCxSpFirst" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Fornecer informações verdadeiras, exatas, atuais e completas nos termos e condições em
          que solicitado;</span
        >
      </p>

      <p class="MsoListParagraphCxSpLast" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Sempre atualizar as informações fornecidas, mantendo verdadeiras, exatas, atuais e
          completas.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Na hipótese da <b>youcheck.in</b> ter algum indício que venha a indicar que qualquer das
          informações fornecidas pelo usuário seja falsa, incorreta, desatualizada ou incompleta,
          poderá ao seu critério suspender ou cancelar o cadastro do usuário e recusar sua
          utilização futura.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Ao realizar o prévio cadastro na plataforma <b>WebApp</b>, o usuário deverá informar seu
          nome completo, seu endereço de email e fornecer uma todo atualizada.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >A <b>youcheck.in</b> se compromete a não divulgar quaisquer dados pessoais e dados
          sensíveis do usuário, nos termos da política de privacidade prevista no
          <b><u>Termo de Privacidade</u></b
          >.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >&nbsp;</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >&nbsp;</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Cancelamento e não comparecimento
          </span></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Ao realizar o prévio check-in pela plataforma<b> WebApp</b> e confirmar o check-in na
          plataforma <b>balcão de check-in self-service </b>de um pedido de reserva de uma estadia,
          o usuário aceita e concorda com as condições de cancelamento e de não comparecimento nos
          termos e condições da política do provedor de acomodação ou de viagem, eximindo-se a
          <b>youcheck.in</b> de qualquer responsabilidade. Nessa hipótese, é importante que o
          usuário observe a incidência de taxas e multas ou ofertas que implicam na impossibilidade
          de cancelamento, reembolsos e pagamentos na hipótese de cancelamento e de não
          comparecimento.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >A <b>youcheck.in </b>não realiza o cancelamento de sua reserva de estadia. Nesse caso, o
          usuário deverá entrar em contato diretamente com o provedor de acomodação que realizou o
          pedido de reserva.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Caso se atrase ou chegue tarde no dia do check-in, certifique de comunicar imediatamente
          e a tempo o provedor de acomodação que irá hospedá-lo, sob pena de cancelamento da reserva
          ou cobrança de taxa de não comparecimento.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Cartão de crédito ou débito</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >A plataforma <b>balcão de check-in self-service </b>da <b>youcheck.in </b>está habilitada
          para realizar o check-out do usuário. A partir do reconhecimento do usuário, será
          fornecida a fatura da estadia e/ou dos serviços de hotelaria do provedor de acomodação
          para que seja realizada a cobrança. Se o usuário estiver de acordo, serão solicitadas
          informações de pagamento para que seja realizado o pagamento por meio de cartão de débito
          ou crédito.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >A política de pagamento a ser adotada é de responsabilidade exclusiva do provedor de
          acomodação.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >A <b>youcheck.in </b>opera em parceria com empresas que gerenciam o pagamento
          <i>on line </i>de forma segura. A <b>youcheck.in </b>opera apenas e tão somente como uma
          facilitadora para realizar o pagamento.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >O pagamento é processado no seu cartão de crédito ou cartão de débito para a conta
          bancária do provedor de acomodação por meio de uma empresa terceirizada.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Aviso legal</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Sujeitos às limitações fixadas neste <b>Termo e Condições de Uso</b>, nos limites
          estabelecidos por lei, a <b>youcheck.in</b> é responsável somente por danos diretos
          incorridos ou pagos pelo usuário em função de uma falha atribuída pelas obrigações
          assumidas no limite do valor total do custo global do pedido de reserva ao provedor de
          acomodação realizada nas plataformas da <b>youcheck.in.</b></span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif;
color:#333333'
          >Nos termos da lei, a </span
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >youcheck.in</span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >, seus sócios, funcionários, representantes, parceiros não poderão ser responsabilizados
          por </span
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;
line-height:107%;font-family:"Futura Lt BT",sans-serif;color:#333333'
          >(i) qualquer dano ou perda punível, especial, indireta ou consequente, qualquer perda de
          produção, lucro, rendimentos, contrato, e perda ou dano de influência ou reputação, perda
          do direito à indenização, (ii) qualquer erro com relação às informações (descritivas)
          (incluindo tarifas, disponibilidade e classificações) do provedor de acomodação tal como
          aparece nas plataformas da </span
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >youcheck.in</span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif;
color:#333333'
          >, (iii) quaisquer danos (diretos, indiretos, consequentes ou puníveis), perdas ou custos
          suportados, incorridos ou pagos pelo usuário, conforme, resultante de ou em ligação com o
          uso, impossibilidade de uso ou falha das plataformas da </span
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;
line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >youcheck.in</span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif;
color:#333333'
          >, ou (iv) qualquer prejuízo (pessoal), morte, danos na propriedade ou outros (diretos,
          indiretos, especiais, consequentes ou puníveis) danos, perdas ou custos suportados,
          incorridos ou pagos pelo usuário, seja devido a atos (legais), erros, infrações,
          negligência (grave), conduta imprópria intencional, omissões, não cumprimento, informações
          falsas, delito ou responsabilidade extracontratual objetiva (totalmente ou parcialmente)
          ao provedor de acomodação ou qualquer de seus parceiros de negócios (incluindo seus
          funcionários, diretores administrativos, agentes, representantes, subcontratados ou
          empresas afiliadas), de quem produtos ou serviços estejam (direta ou indiretamente)
          disponíveis, sejam oferecidos ou promovidos em ou por meio da Plataforma, incluindo
          qualquer cancelamento (parcial), overbookings (sobrelotações), greve, força maior ou
          qualquer outro evento que fuja do nosso controle.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif;
color:#333333'
          >No caso do provedor de acomodação tenha cobrado o usuário por sua estadia ou serviços de
          hotelaria, ainda que a </span
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >youcheck.in</span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
        >
          tenha</span
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;
font-family:"Futura Lt BT",sans-serif;color:#333333'
        >
          atuado como como facilitadora do pagamento, o usuário está ciente e concorda que apenas e
          exclusivamente o provedor de acomodação será o responsável pela cobrança, retenção na
          fonte, remessa e pagamento dos impostos aplicáveis relativos aos serviços às autoridades
          fiscais competentes. A </span
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >youcheck.in</span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif;
color:#333333'
        >
          não é responsável legalmente pela notificação de pagamento, cobrança, retenção na fonte ou
          pagamento dos impostos correspondentes aos serviços prestados e produtos comercializados
          pelo provedor de acomodação às autoridades fiscais competentes. A </span
        ><b
          ><span
            lang="PT-BR"
            style='font-size:
12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >youcheck.in</span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif;
color:#333333'
        >
          não atua em qualquer atividade de comércio para qualquer produto ou serviço
          disponibilizado nas plataformas em que é proprietária.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif;
color:#333333'
          >&nbsp;</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif;
color:#333333'
            >Acordo Integral</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif;
color:#333333'
          >Este <b>Termo e Condições de Uso</b> constitui o acordo integral entre a </span
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;
font-family:"Futura Lt BT",sans-serif'
            >youcheck.in
          </span></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >e o usuário, prevalecendo sobre qualquer outro entendimento firmado anteriormente.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Qualquer comunicação por parte do usuário em face da <b>youcheck.in </b>deverá ser feita
          por meio do seguinte e-mail suporte@youcheck.in.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif;
color:#333333'
            >Legislação aplicável, jurisdição e resolução de litígio
          </span></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif;
color:#333333'
          >Este </span
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;
line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Termo e Condições de Uso
          </span></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;
font-family:"Futura Lt BT",sans-serif'
          >é regido e interpretado segundo as leis da República Federativa do Brasil e no idioma
          português.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Qualquer litígio resultante deste Termo e Condições de Uso será exclusivamente submetido
          ao Foro Central da Comarca de São Paulo, Estado de São Paulo, República Federativa do
          Brasil, sendo excluído qualquer outro por mais privilegiado que seja.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Na hipótese de qualquer disposição deste Termo e Condições de Uso for ou se tornar
          inválida, inexecutável ou não vinculativa, o usuário permanecerá vinculado a todas as
          outras disposições.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Contato</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Quaisquer assuntos relacionados a esse Termo e Condições de Uso devem ser endereçados ao
          seguinte contato: suporte@youcheck.in.</span
        >
      </p>
    </div>
    <b-button @click="close">Ok</b-button>
  </b-modal>
</template>

<script>
export default {
  name: "TermsModal",
  methods: {
    close() {
      this.$bvModal.hide("terms-modal");
    }
  }
};
</script>

<style lang="scss">
#terms-modal.modal {
  position: fixed;
  height: 100%;
  z-index: 1050;

  .modal-body {
    display: flex;
    flex-direction: column;
    padding: 40px;

    .btn {
      margin-left: 0;
      margin-right: 0;
      margin-top: 20px;
      font-size: 14px;
    }

    iframe {
      height: 400px;
    }
  }

  /* Style Definitions */
  p.MsoNormal,
  li.MsoNormal,
  div.MsoNormal {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 8pt;
    margin-left: 0in;
    line-height: 107%;
    font-size: 11pt;
  }
  p.MsoListParagraph,
  li.MsoListParagraph,
  div.MsoListParagraph {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 8pt;
    margin-left: 0.5in;
    line-height: 107%;
    font-size: 11pt;
  }
  p.MsoListParagraphCxSpFirst,
  li.MsoListParagraphCxSpFirst,
  div.MsoListParagraphCxSpFirst {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 0.5in;
    line-height: 107%;
    font-size: 11pt;
  }
  p.MsoListParagraphCxSpMiddle,
  li.MsoListParagraphCxSpMiddle,
  div.MsoListParagraphCxSpMiddle {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 0.5in;
    line-height: 107%;
    font-size: 11pt;
  }
  p.MsoListParagraphCxSpLast,
  li.MsoListParagraphCxSpLast,
  div.MsoListParagraphCxSpLast {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 8pt;
    margin-left: 0.5in;
    line-height: 107%;
    font-size: 11pt;
  }
  .MsoPapDefault {
    margin-bottom: 8pt;
    line-height: 107%;
  }
  @page WordSection1 {
    size: 595.3pt 841.9pt;
    margin: 70.85pt 85.05pt 70.85pt 85.05pt;
  }
  div.WordSection1 {
    page: WordSection1;
  }
  /* List Definitions */
  ol {
    margin-bottom: 0in;
  }
  ul {
    margin-bottom: 0in;
  }
}
</style>
