var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{attrs:{"rules":_vm.validationRules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"virtual-keyboard-input"},[_c('v-popper',{ref:"popper",attrs:{"trigger":"clickToOpen","options":_vm.popperSettings},on:{"show":_vm.eventEmissionFocus,"hide":_vm.eventEmissionBlur,"blur":_vm.eventEmissionBlur}},[_c('div',{staticClass:"popper-keyboard-container popper",class:{ 'numeric-keyboard': _vm.useNumericKeyboardClass }},[_c('AppVirtualKeyboard',{attrs:{"keyboardInstance":_vm.keyboardInstance,"keyboardType":_vm.keyboardLayout,"keyboardConfirmText":_vm.keyboardConfirmText},on:{"onKeyPress":_vm.keyPressedHandler},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('b-form-group',{attrs:{"slot":"reference","id":`${_vm.inputId}-group`,"label":_vm.label,"label-for":_vm.inputId,"invalid-feedback":errors[0],"state":!errors.length},slot:"reference"},[(!_vm.telLib)?_c('b-form-input',{attrs:{"autocomplete":"off","id":_vm.inputId,"value":_vm.model,"name":_vm.name,"type":"text","placeholder":_vm.placeholder,"trim":""}}):_vm._e(),(_vm.telLib)?_c('vue-tel-input',{staticClass:"input-phone",attrs:{"autocomplete":"off","defaultCountry":"BR","mode":"international","autoFormat":true,"inputOptions":{
            showDialCode: true
          },"dropdownOptions":{
            width: '550px',
            height: '400px',
            showFlags: true
          }},on:{"close":_vm.telLib},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('template',{slot:"arrow-icon"},[_c('span',{staticClass:"vti__dropdown-arrow"},[_vm._v(" ▼")])])],2):_vm._e(),(_vm.useMask)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"},{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.model)},on:{"input":function($event){if($event.target.composing)return;_vm.model=$event.target.value}}}):_vm._e()],1)],1),(_vm.hasHideButtons && _vm.isHide)?_c('img',{staticClass:"password-icon",attrs:{"src":require("@/assets/icons/eye-slash.svg"),"alt":""},on:{"click":_vm.handleEventShow}}):_vm._e(),(_vm.hasHideButtons && !_vm.isHide)?_c('img',{staticClass:"password-icon",attrs:{"src":require("@/assets/icons/eye.svg"),"alt":""},on:{"click":_vm.handleEventHide}}):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }