import { render, staticRenderFns } from "./SavedCard.vue?vue&type=template&id=b84db5fc&scoped=true"
import script from "./SavedCard.vue?vue&type=script&lang=js"
export * from "./SavedCard.vue?vue&type=script&lang=js"
import style0 from "./SavedCard.vue?vue&type=style&index=0&id=b84db5fc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b84db5fc",
  null
  
)

export default component.exports