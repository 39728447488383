<template>
  <div :class="[keyboardInstance]"></div>
</template>

<script>
import Keyboard from "simple-keyboard";
import Layouts from "@/scripts/virtualKeyboardLayout.js";

export default {
  name: "AppVirtualKeyboard",
  props: {
    keyboardType: {
      type: String,
      default: "fullLayout"
    },
    value: {
      type: String
    },
    keyboardInstance: {
      type: String
    },
    keyboardConfirmText: {
      type: String
    }
  },
  mounted() {
    this.createKeyboardInstance();
  },
  data() {
    return {
      keyboard: null
    };
  },
  computed: {
    keyboardLayout() {
      return Layouts[this.keyboardType];
    }
  },
  methods: {
    createKeyboardInstance() {
      const options = this.getKeyboardOptions();
      this.keyboard = new Keyboard(`.${this.keyboardInstance}`, options);
    },
    getKeyboardOptions() {
      return {
        onChange: this.onChange,
        onKeyPress: this.onKeyPress,
        disableCaretPositioning: true,
        theme: "hg-theme-default hg-layout-default",
        layout: {
          default: this.keyboardLayout
        },
        buttonTheme: [
          { class: "space-key", buttons: "{space}" },
          { class: "arrow-left", buttons: "{bksp}" },
          { class: "shift", buttons: "{shift}" },
          { class: "enter-key", buttons: "{enter}" }
        ],
        display: {
          "{bksp}": " ",
          "{enter}": this.keyboardConfirmText || this.$t("keyboard.enter"),
          "{space}": " ",
          "{shift}": " "
        },
        preventMouseDownDefault: true,
        stopMouseDownPropagation: true
      };
    },
    onChange(input) {
      this.$emit("input", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);
    }
  },
  watch: {
    value(input) {
      this.keyboard.setInput(input);
    }
  }
};
</script>

<style lang="scss">
.hg-theme-default {
  border: 0 1px 1px 1px solid $keyboard-border;
  .hg-row {
    justify-content: center;
  }
  .hg-button {
    height: 45px;
    width: 70px;
    max-width: 70px;
    min-width: 70px;
    font-size: 26px;
    font-weight: bold;

    &.enter-key {
      width: 145px;
      max-width: 145px;
      min-width: 145px;
      background-color: $yckLightGrey;

      &.hg-activeButton {
        background-color: $yckLightGrey;
      }

      span {
        color: $white;
        font-size: 16px;
      }
    }

    &.space-key {
      max-width: unset;
    }

    &.arrow-left {
      span::after {
        content: "\21E6";
      }
    }
  }
}

.numeric-keyboard {
  .hg-theme-default {
    .hg-row {
      justify-content: center;
    }
    .hg-button {
      width: 140px;
      max-width: 140px;
      min-width: 140px;
    }
  }
}
</style>
