<template>
  <div class="custom-list">
    <!-- <h1>{{ $t("message.crewListTitle") }}</h1> -->
    <h1>Lista de Segmentações</h1>

    <b-card bg-variant="dark" no-body class="field-holder">
      <div class="field-group">
        <div class="segmentation-label">
          <label class="required" for="checkinHour">{{
            this.$t("booking_configuration.hostType")
          }}</label>
          <v-select
            id="checkinHour"
            label="description"
            :options="guestTypeList"
            v-model="selectedGuestType"
            class="segmentation-select"
            :clearable="false"
          />
        </div>
        <div class="segmentation-label">
          <label class="required" for="checkinHour">{{
            this.$t("booking_configuration.client")
          }}</label>
          <v-select
            id="checkinHour"
            label="name"
            v-debounce:1s="fetchOptions"
            debounce-events="input"
            :options="clientList"
            v-model.lazy="selectedClient"
            class="segmentation-select"
            :clearable="false"
          />
        </div>
        <div class="segmentation-label">
          <label class="required" for="checkinHour">{{
            this.$t("booking_configuration.origin")
          }}</label>
          <v-select
            id="checkinHour"
            label="description"
            :options="originsList"
            v-model="selectedOrigin"
            class="segmentation-select"
            :clearable="false"
          />
        </div>
        <div class="segmentation-label">
          <label class="required" for="checkinHour">{{
            this.$t("booking_configuration.segment")
          }}</label>
          <v-select
            id="checkinHour"
            label="description"
            :options="segmentsList"
            v-model="selectedSegment"
            class="segmentation-select"
            :clearable="false"
          />
        </div>
        <div class="segmentation-label">
          <label class="required" for="checkinHour">{{
            this.$t("booking_configuration.vehicle")
          }}</label>
          <v-select
            id="checkinHour"
            label="description"
            :options="mediasList"
            v-model="selectedMedia"
            class="segmentation-select"
            :clearable="false"
          />
        </div>
        <div class="segmentation-label">
          <label class="required" for="checkinHour">{{
            this.$t("booking_configuration.canDoCheckin")
          }}</label>
          <v-select
            id="checkinHour"
            label="label"
            :options="checkinList"
            v-model="selectedCheckin"
            class="segmentation-select"
            :clearable="false"
          />
        </div>
        <div class="segmentation-label">
          <label class="required" for="checkinHour">{{
            this.$t("booking_configuration.canDoCheckout")
          }}</label>
          <v-select
            id="checkinHour"
            label="label"
            :options="checkoutList"
            v-model="selectedCheckout"
            class="segmentation-select"
            :clearable="false"
          />
        </div>
        <div class="segmentation-label">
          <label class="required" for="checkinHour">{{
            this.$t("booking_configuration.paymentOfPerDiems")
          }}</label>
          <v-select
            id="checkinHour"
            label="label"
            :options="paymentOptionList"
            v-model="selectedPaymentOption"
            class="segmentation-select"
            :clearable="false"
          />
        </div>
        <div class="segmentation-label">
          <label class="required" for="checkinHour">{{
            this.$t("booking_configuration.documentPhoto")
          }}</label>
          <v-select
            id="checkinHour"
            label="label"
            :options="documentOptionList"
            v-model="selectedDocumentOption"
            class="segmentation-select"
            :clearable="false"
          />
        </div>
        <div class="segmentation-label">
          <label class="required" for="checkinHour">{{
            this.$t("booking_configuration.facialRecognition")
          }}</label>
          <v-select
            id="checkinHour"
            label="label"
            :options="facialOptionList"
            v-model="selectedFacialOption"
            class="segmentation-select"
            :clearable="false"
          />
        </div>
        <div class="segmentation-label">
          <label class="required" for="checkinHour">{{
            this.$t("booking_configuration.sendSMS")
          }}</label>
          <v-select
            id="checkinHour"
            label="label"
            :options="SMSOptionList"
            v-model="selectedSMSOption"
            class="segmentation-select"
            :clearable="false"
          />
        </div>
      </div>
    </b-card>
    <span v-if="ruleId == null">
      <b-button variant="primary" @click="setNewRule">{{
        this.$t("booking_configuration.createRule")
      }}</b-button>
    </span>
    <span v-else>
      <b-button variant="primary" :disabled="loadingData" @click="editRule">{{
        this.$t("booking_configuration.editRule")
      }}</b-button>
      <b-button variant="primary" @click="setDefault">{{
        this.$t("booking_configuration.cancel")
      }}</b-button>
      <b-button variant="primary" @click="deleteRule">{{
        this.$t("booking_configuration.delete")
      }}</b-button>
    </span>
    <b-card bg-variant="dark" no-body class="table-holder">
      <b-table
        :items="items"
        :fields="fields"
        responsive
        striped
        borderless
        dark
        sticky-header="calc(100vh - 380px)"
        table-variant="dark"
        @row-clicked="setToEdit"
      ></b-table>
      <transition name="fade">
        <app-loader v-if="loadingData" :isAdmin="true" />
      </transition>
    </b-card>
  </div>
</template>

<script>
// import moment from "moment";

export default {
  name: "BookingConfiguration",
  components: {},
  computed: {},
  data() {
    return {
      fields: [
        {
          key: "guestTypeName",
          label: "Tipo de Hospede",
          sortable: true,
          formatter: value => {
            if (value === null) {
              return "Todos";
            }
            return value;
          }
        },
        {
          key: "customerName",
          label: "Cliente",
          sortable: true,
          formatter: value => {
            if (value === null) {
              return "Todos";
            }
            return value;
          }
        },
        {
          key: "originName",
          label: "Origem",
          sortable: true,
          formatter: value => {
            if (value === null) {
              return "Todos";
            }
            return value;
          }
        },
        {
          key: "segmentName",
          label: "Segmento",
          sortable: true,
          formatter: value => {
            if (value === null) {
              return "Todos";
            }
            return value;
          }
        },
        {
          key: "mediaName",
          label: "Veículo",
          sortable: true,
          formatter: value => {
            if (value === null) {
              return "Todos";
            }
            return value;
          }
        },
        {
          key: "checkinEnabled",
          label: "Pode fazer checkin",
          sortable: true,
          formatter: value => {
            if (value === true) {
              return "Sim";
            }
            return "Não";
          }
        },
        {
          key: "checkoutEnabled",
          label: "Pode fazer checkout",
          sortable: true,
          formatter: value => {
            if (value === true) {
              return "Sim";
            }
            return "Não";
          }
        },
        {
          key: "reservationPaymentId",
          label: "Pagamento das Diárias",
          sortable: true,
          formatter: value => {
            if (value == 1) {
              return "Checkin";
            }
            return "Checkout";
          }
        },
        {
          key: "documentPhotoEnabled",
          label: "Foto do Documento",
          sortable: true,
          formatter: value => {
            if (value === true) {
              return "Sim";
            }
            return "Não";
          }
        },
        {
          key: "facialRecognitionEnabled",
          label: "Reconhecimento Facial",
          sortable: true,
          formatter: value => {
            if (value === true) {
              return "Sim";
            }
            return "Não";
          }
        },
        {
          key: "smsEnabled",
          label: "Enviar SMS",
          sortable: true,
          formatter: value => {
            if (value === true) {
              return "Sim";
            }
            return "Não";
          }
        }
      ],
      items: [],
      ruleId: null,
      loadingData: true,
      guestTypeList: [],
      selectedGuestType: { description: "Selecione", isDefault: true },
      clientList: [{ customerId: null, name: "Todos" }],
      selectedClient: { name: "Selecione", isDefault: true },
      originsList: [],
      selectedOrigin: { description: "Selecione", isDefault: true },
      segmentsList: [],
      selectedSegment: { description: "Selecione", isDefault: true },
      mediasList: [],
      selectedMedia: { description: "Selecione", isDefault: true },
      checkinList: [
        { value: false, label: "não" },
        { value: true, label: "sim" }
      ],
      selectedCheckin: { label: "Selecione", isDefault: true },
      checkoutList: [
        { value: false, label: "não" },
        { value: true, label: "sim" }
      ],
      selectedCheckout: { label: "Selecione", isDefault: true },
      paymentOptionList: [
        { value: 1, label: "Checkin" },
        { value: 2, label: "Checkout" }
      ],
      selectedPaymentOption: { label: "Selecione", isDefault: true },
      documentOptionList: [
        { value: true, label: "sim" },
        { value: false, label: "não" }
      ],
      selectedDocumentOption: { label: "Selecione", isDefault: true },
      facialOptionList: [
        { value: true, label: "sim" },
        { value: false, label: "não" }
      ],
      selectedFacialOption: { label: "Selecione", isDefault: true },
      SMSOptionList: [
        { value: true, label: "sim" },
        { value: false, label: "não" }
      ],
      selectedSMSOption: { label: "Selecione", isDefault: true }
    };
  },
  methods: {
    fetchOptions(e) {
      if (e.length > 5) {
        this.getCustomerList(e);
      }
    },
    getGuestType() {
      this.$API.bookingConfiguration
        .listGuestTypes()
        .then(data => {
          this.guestTypeList = data;
          this.guestTypeList.unshift({ guestTypeId: null, description: "Todos" });
        })
        .catch(() => {})
        .finally(() => {
          this.loadingData = false;
        });
    },
    async getCustomerList(string) {
      await this.$API.bookingConfiguration
        .searchCustomer(string)
        .then(data => {
          this.clientList = data;
          this.clientList.unshift({ customerId: null, name: "Todos" });
        })
        .catch(() => {})
        .finally(() => {});
    },
    getOriginsList() {
      this.$API.bookingConfiguration
        .listOrigins()
        .then(data => {
          this.originsList = data;
          this.originsList.unshift({ originId: null, description: "Todos" });
        })
        .catch(() => {})
        .finally(() => {});
    },
    getSegmentsList() {
      this.$API.bookingConfiguration
        .listSegments()
        .then(data => {
          this.segmentsList = data;
          this.segmentsList.unshift({ segmentCode: null, description: "Todos" });
        })
        .catch(() => {})
        .finally(() => {});
    },
    getMediasList() {
      this.$API.bookingConfiguration
        .listMedias()
        .then(data => {
          this.mediasList = data;
          this.mediasList.unshift({ mediaId: null, description: "Todos" });
        })
        .catch(() => {})
        .finally(() => {});
    },
    getConfigurations() {
      this.$API.bookingConfiguration
        .listBookingConfigurations()
        .then(data => {
          this.items = data;
        })
        .catch(() => {})
        .finally(() => {});
    },
    setDefault() {
      this.ruleId = null;
      this.selectedGuestType = { description: "Selecione", isDefault: true };
      this.selectedClient = { name: "Selecione", isDefault: true };
      this.selectedOrigin = { description: "Selecione", isDefault: true };
      this.selectedSegment = { description: "Selecione", isDefault: true };
      this.selectedMedia = { description: "Selecione", isDefault: true };
      this.selectedCheckin = { label: "Selecione", isDefault: true };
      this.selectedCheckout = { label: "Selecione", isDefault: true };
      this.selectedPaymentOption = { label: "Selecione", isDefault: true };
      this.selectedDocumentOption = { label: "Selecione", isDefault: true };
      this.selectedFacialOption = { label: "Selecione", isDefault: true };
      this.selectedSMSOption = { label: "Selecione", isDefault: true };
    },
    setToEdit(item) {
      this.ruleId = item.id;

      this.selectedGuestType =
        item.guestTypeId != null
          ? this.guestTypeList.find(guestType => guestType.guestTypeId == item.guestTypeId)
          : this.guestTypeList.find(guestType => guestType.guestTypeId == null);

      if (item.customerId == null) {
        this.selectedClient = this.clientList.find(client => client.customerId == null);
        this.loadingData = false;
      } else {
        this.getCustomerList(item.customerName).then(() => {
          this.loadingData = false;
          this.selectedClient = this.clientList.find(
            client => client.customerId == item.customerId
          );
        });
      }

      this.selectedOrigin =
        item.originId != null
          ? this.originsList.find(origin => origin.originId == item.originId)
          : this.originsList.find(origin => origin.originId == null);

      this.selectedSegment =
        item.segmentCode != null
          ? this.segmentsList.find(segment => segment.segmentCode == item.segmentCode)
          : this.segmentsList.find(segment => segment.segmentCode == null);

      this.selectedMedia =
        item.mediaId != null
          ? this.mediasList.find(media => media.mediaId == item.mediaId)
          : this.mediasList.find(media => media.mediaId == null);

      this.selectedCheckin = this.checkinList.find(option => option.value == item.checkinEnabled);

      this.selectedCheckout = this.checkoutList.find(option => option.value == item.checkoutEnabled);

      this.selectedPaymentOption = this.paymentOptionList.find(option => option.value == item.reservationPaymentId);

      this.selectedDocumentOption = this.documentOptionList.find(option => option.value == item.documentPhotoEnabled);

      this.selectedFacialOption = this.facialOptionList.find(option => option.value == item.facialRecognitionEnabled);

      this.selectedSMSOption = this.SMSOptionList.find(option => option.value == item.smsEnabled);
    },
    validateFields() {
      if (
        !this.selectedGuestType.isDefault &&
        !this.selectedClient.isDefault &&
        !this.selectedOrigin.isDefault &&
        !this.selectedSegment.isDefault &&
        !this.selectedMedia.isDefault &&
        !this.selectedCheckin.isDefault &&
        !this.selectedCheckout.isDefault &&
        !this.selectedPaymentOption.isDefault &&
        !this.selectedDocumentOption.isDefault &&
        !this.selectedFacialOption.isDefault &&
        !this.selectedSMSOption.isDefault
      )
        return true;
      return false;
    },
    setNewRule() {
      if (!this.validateFields()) {
        this.defaultAlert(5);
        return;
      }

      const data = {
        guestTypeId: this.selectedGuestType.guestTypeId,
        customerId: this.selectedClient.customerId,
        customerName: this.selectedClient.name,
        originId: this.selectedOrigin.originId,
        segmentCode: this.selectedSegment.segmentCode,
        mediaId: this.selectedMedia.mediaId,
        checkinEnabled: this.selectedCheckin.value,
        checkoutEnabled: this.selectedCheckout.value,
        documentPhotoEnabled: this.selectedDocumentOption.value,
        facialRecognitionEnabled: this.selectedFacialOption.value,
        smsEnabled: this.selectedSMSOption.value,
        reservationPaymentId: this.selectedPaymentOption.value
      };

      this.$API.bookingConfiguration
        .createBookingConfiguration(data)
        .then(response => {
          data.id = response.data;
          data.reservationPayment = data.reservationPaymentId == 1 ? "checkin" : "checkout";
          this.ruleId = null;
          this.loadingData = true;
          this.setDefault();
          this.defaultAlert(1);
          this.getConfigurations();
        })
        .catch(() => {
          this.defaultAlert(4);
        });
    },
    editRule() {
      const data = {
        id: this.ruleId,
        guestTypeId: this.selectedGuestType.guestTypeId,
        customerId: this.selectedClient.customerId,
        customerName: this.selectedClient.name,
        originId: this.selectedOrigin.originId,
        segmentCode: this.selectedSegment.segmentCode,
        mediaId: this.selectedMedia.mediaId,
        checkinEnabled: this.selectedCheckin.value,
        checkoutEnabled: this.selectedCheckout.value,
        documentPhotoEnabled: this.selectedDocumentOption.value,
        facialRecognitionEnabled: this.selectedFacialOption.value,
        smsEnabled: this.selectedSMSOption.value,
        reservationPaymentId: this.selectedPaymentOption.value
      };

      this.$API.bookingConfiguration
        .updateBookingConfiguration(data)
        .then(() => {
          this.ruleId = null;
          this.loadingData = true;
          this.setDefault();
          this.getConfigurations();
          this.defaultAlert(2);
        })
        .catch(() => {
          this.defaultAlert(4);
        });
    },
    defaultAlert(value) {
      let label = "";
      switch (value) {
        case 1:
          label = this.$t("booking_configuration.createdMessage");
          break;

        case 2:
          label = this.$t("booking_configuration.updatedMessage");
          break;

        case 3:
          label = this.$t("booking_configuration.deletedMessage");
          break;

        case 4:
          label = this.$t("booking_configuration.errorMessage");
          break;

        case 5:
          label = this.$t("booking_configuration.fieldsErrorMessage");
          break;

        default:
          label = this.$t("booking_configuration.updatedMessage");
          break;
      }
      this.$swal.fire({
        title: label,
        icon: "info",
        width: 500,
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        allowOutsideClick: true,
        confirmButtonText: `OK`,
        confirmButtonColor: "black",
        iconColor: "black",
        background: "#ffd400"
      });
    },
    async deleteRule() {
      const id = this.ruleId;
      await this.$swal
        .fire({
          title: this.$t("booking_configuration.askToDelete"),
          width: 400,
          imageUrl: this.companyLogoUrl,
          imageWidth: 200,
          imageHeight: 120,
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          allowOutsideClick: false,
          confirmButtonText: `<label style="font-size:18px; color: black; cursor: pointer;">${this.$t(
            "message.crewOk"
          )}</label>`,
          cancelButtonText: `<label style="font-size:18px; color: black; cursor: pointer;">${this.$t(
            "booking_configuration.cancel"
          )}</label>`,
          cancelButtonColor: "#ffd400",
          confirmButtonColor: "#ffd400",
          background: "#ffffff"
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$API.bookingConfiguration
              .deleteBookingConfiguration(id)
              .then(() => {
                this.items = this.items.filter(element => {
                  return element.id != id;
                });
                this.ruleId == null;
                this.setDefault();
                this.defaultAlert(3);
              })
              .catch(() => {
                this.defaultAlert(4);
              });
          }
        });
    }
  },
  mounted() {
    this.getGuestType();
    this.getOriginsList();
    this.getSegmentsList();
    this.getMediasList();
    this.getConfigurations();
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.field-group {
  display: flex;
  align-items: flex-end;
  widows: 100%;
  justify-content: space-between;
}

.field-holder {
  padding: 10px;
  margin-bottom: 15px;
}

.table-holder {
  margin-top: 15px;
}

.segmentation-select {
  background-color: #fff;
}

.segmentation-label {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  margin-right: 5px;

  &:nth-child(6),
  &:nth-child(7) {
    max-width: 110px;
  }

  & label {
    color: #fff;
  }
}
.px-15 {
  padding: 0px 15px;
}

.date-filters-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}
</style>
