export default {
  fullLayout: [
    "1 2 3 4 5 6 7 8 9 0",
    "Q W E R T Y U I O P",
    "A S D F G H J K L",
    "Z X C V B N M {bksp}",
    "{space} {enter}"
  ],
  name: ["Q W E R T Y U I O P", "A S D F G H J K L", "Z X C V B N M {bksp}", "{space} {enter}"],
  email: [
    "1 2 3 4 5 6 7 8 9 0 - _",
    "Q W E R T Y U I O P = +",
    "A S D F G H J K L : ;",
    "Z X C V B N M , . ! {bksp}",
    "{space} @ .com {enter}"
  ],
  numeric: ["1 2 3", "4 5 6", "7 8 9", "{bksp} 0 {enter}"],
  cel: ["1 2 3", "4 5 6", "7 8 9", "+ 0 {bksp}", "{enter}"]
};
