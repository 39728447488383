<template>
  <b-modal id="privacy-modal" hide-header hide-footer no-close-on-backdrop size="lg">
    <div class="WordSection1">
      <p class="MsoNormal" align="center" style="text-align:center">
        <a name="_Hlk64622946"
          ><b
            ><span
              lang="PT-BR"
              style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
              >TERMO DE PRIVACIDADE DA YOUCHECK.IN</span
            ></b
          ></a
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Queremos que a experiência com nosso balcão de check-in e check-out self-service facilite
          a vida dos hóspedes de hotéis reduzindo o tempo na entrada e saída de locais acessíveis ao
          público. Dessa forma, para que o uso do <b>youcheck.in</b> possa ser fácil e ágil,
          assumimos o compromisso com os usuários (ou “Você”) para assegurar que o tratamento dos
          seus dados pessoais seja feito de acordo com o expresso fornecimento de seu consentimento
          e nos limites da lei.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Por meio desse documento, chamado <b>Termo de Privacidade</b>, declaramos como será
          realizada
          <b
            ><i
              >a recepção, utilização, transmissão, arquivamento, armazenagem, modificação,
              compartilhamento e eliminação dos seus dados pessoais</i
            ></b
          >. Este <b>Termo de Privacidade</b>, também, assegura como Você pode exercer seus direitos
          em relação aos seus dados pessoais, se opondo ou entrando em contato conosco.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Ao concordar com este Termo de Privacidade, Você declara e concorda que a
          <b>youcheck.in </b>pode tratar seus dados pessoais em conformidade com os termos aqui
          estabelecidos.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Aplicação deste Termo de Privacidade</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Este Termo de Privacidade é aplicável aos usuários da plataforma<b> WebApp</b> para
          realizar o prévio check-in e da plataforma <b>balcão de check-in self-service</b>, para
          que a <b>youcheck.in </b>possa se amparar em seu legítimo interesse comercial na prestação
          dos serviços que oferece em suas plataformas, prevenção de fraudes e melhoria dos serviços
          prestados.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Este Termo de Privacidade não se aplica a aplicativos, sites ou serviços de terceiros que
          possam ser acessados através de links que possam ser fornecidos para sua conveniência e
          informação. O acesso a esses links fará com que Você sais do ambiente virtual da
          <b>youcheck.in </b>e pode resultar na coleta ou compartilhamento de informações sobre Você
          por terceiros. A <b>youcheck.in </b>não controla, endossa ou concede quaisquer garantias a
          respeito de aplicações de terceiros ou a respeito de suas práticas de privacidade.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Como é feita a coleta das informações</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <a name="_Hlk64622911"
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >A <b>youcheck.in</b> encaminha antecipadamente um link para que Você possa inserir seus
            dados pessoais e os dados do(s) acompanhante(s) que hospederá(ão) com Você para que a
            <b>youcheck.in</b> possa agilizar seu cadastro em um provedor de acomodação (hotel,
            locador etc.) que Você efetuou o seu pedido de reserva de estadia. Seus dados pessoais
            sensíveis são armazenadas pela <b>youcheck.in</b>.<b> </b>O<b> </b>provedor de
            acomodação não terá acesso aos seus dados pessoais sensíveis, mas, tão somente aos seus
            dados pessoais. Na data do check-in, ao utilizar o
            <b>balcão de check-in self-service</b> da <b>youcheck.in</b>, seus dados pessoais
            arquivados serão reconhecidos pela tecnologia ou será necessário fornecer, novamente, os
            dados pessoais não reconhecidos, modificados ou eliminados.
          </span></a
        ><a name="_Hlk64643561"
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;
font-family:"Futura Lt BT",sans-serif'
            >Ao utilizar o <b>balcão de check-in self-service</b>, a tecnologia escolherá um quarto
            vago no provedor de acomodação, fornecendo um cartão de acesso à acomodação
            descodificada para que Você possa codificar os dados e permitir seu acesso à
            acomodação<b>.</b> No check-out, Você será novamente submetido ao reconhecimento pela
            tecnologia ou será necessário fornecer, novamente, os dados pessoais, caso não tenha
            feito o check-in no <b>balcão de check-in self-service</b>. A partir do seu
            reconhecimento, será fornecida a fatura da estadia e/ou dos serviços de hotelaria do
            provedor de acomodação para que seja realizada a cobrança. Se Você estiver de acordo,
            serão solicitadas informações de pagamento para que seja realizado o pagamento por meio
            de cartão de débito ou crédito.
          </span></a
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >A <b>youcheck.in </b>também coleta informações de dados pessoais por meio de cookies e
          outras tecnologias similares contidas no site </span
        ><span lang="PT-BR"
          ><a href="https://www.youcheck.in/"
            ><span
              style='font-size:12.0pt;
line-height:107%;font-family:"Futura Lt BT",sans-serif'
              >Solução de check-in/out - Youcheck.in</span
            ></a
          ></span
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;
line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >
          </span></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >A <b>youcheck.in </b>poderá receber informações sobre Você de prestadores de serviços que
          são parte do processo de pedido de uma reserva de uma estadia, tais como, provedores de
          acomodação, empresas que gerenciam pagamento <i>online</i>, empresas que realizam análise
          de fraudes, entre outros. Os prestadores de serviços poderão compartilhar com a
          <b>youcheck.in</b> dados pessoais, dados da sua reserva, dados de sua estadia e seu
          consumo ao longo dos serviços de acomodação. Em todos os casos, a <b>youcheck.in </b>se
          compromete em manter todos os dados em confidencialidade absoluta, tratando somente os
          dados pessoais necessários para os serviços de check-in e check-out, cumprir com alguma
          obrigação legal ou, ainda, em outras hipóteses permitidas em lei.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Você sempre será informado pela <b>youcheck.in</b> para fornecimento dos dados pessoais e
          dos dados pessoais sensíveis. Caso Você se recuse a fornecer esses dados, não será
          possível dar continuidade aos serviços de check-in e check-out oferecidos pela
          <b>youcheck.in</b>. Nesse caso, a <b>youcheck.in </b>se reserva no direito de não prestar
          os serviços de check-in e check-out oferecidos por suas plataformas.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Informações tratadas pela youcheck.in
          </span></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Como dissemos, <b>youcheck.in</b> quer diminuir o seu tempo no check-in e check-out no
          hotel onde Você se hospedará. Por esse motivo, é necessário que sejam fornecidas
          informações para que o serviço seja ágil e possa diminuir a burocracia. Por isso, quando
          Você receber o link da <b>youcheck.in</b>, iremos solicitar os seguintes dados.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Dados pessoais de cadastro</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <a name="_Hlk64642296"
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Pediremos que realize o preenchimento da Ficha Nacional de Registro de Hóspedes – FNRH,
            inserindo seu nome completo, endereço de e-mail</span
          ></a
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >, telefone de contato, profissão, nacionalidade, data de nascimento, sexo, documento de
          identificação e/ou número do passaporte em caso de viagem internacional, residência,
          última procedência, próximo destino, motivo da viagem e meio de transporte.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Dados pessoais sensíveis
          </span></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Pediremos que tire uma foto no <b>WebApp</b> para que seja possível reconhecê-lo no
          <b>balcão de check-in self-service</b>. Em nossa plataforma poderemos dar a opção de
          salvar informações relacionadas ao seu formulário e de sua foto. Se Você salvar essa
          informação, Você poderá adicionar, excluir ou modificar essa informação a qualquer
          momento.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Processamento de pagamentos e informações bancárias</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >A <b>youcheck.in </b>atua em conjunto
          <a name="_Hlk64643728">com empresas que gerenciam o pagamento <i>online</i> </a>realizados
          por Você. É importante que Você esteja ciente que tais empresas poderão coletar e
          armazenar suas informações e dados, inclusive dados bancários e dados de cartão de
          crédito. Em nossa plataforma poderemos dar a opção de salvar informações relacionadas ao
          seu formulário e escolha de pagamento. Se Você salvar essas informações de pagamento, Você
          poderá adicionar, excluir ou modificar essas informações a qualquer momento.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Informações complementares</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >São, também, coletadas informações do seu computador, telefone, tablet ou outros
          dispositivos para acessar o <b>WebApp</b> ou nosso site. Poderão ser coletados dados do
          endereço do IP, o navegador de internet e as configurações de idioma. Poderemos receber
          informações de Você compartilhada por outras partes ou em que coletamos outras informações
          automaticamente.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Informações sobre outras pessoas</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >No caso de Você desejar efetuar o seu pedido de reserva assim como de seu
          acompanhante(s), Você deverá fornecer as informações necessárias para que a
          <b>youcheck.in</b> possa realizar o prévio check-in na <b>WebApp, </b>o check-in e o
          check-out no <b>balcão de check-in self-service</b>. Você deverá informar a outra pessoa
          que compartilhará os dados pessoais com a <b>youcheck.in</b>, sendo este tratamento
          sujeito ao presente <b>Termo de Privacidade</b>.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Dados de crianças e adolescentes</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Caso a sua estadia seja acompanhada por crianças e adolescentes, a
          <b>youcheck.in</b> poderá dar tratamento aos dados pessoais e sensíveis das crianças e
          adolescentes para realizar o prévio check-in na <b>WebApp, </b>o check-in e o check-out no
          <b>balcão de check-in self-service</b>. Nesse caso, a <b>youcheck.in </b>se compromete a
          dar tratamento aos referidos dados pessoais no melhor interesse das crianças e dos
          adolescentes. Caso sejam fornecidos dados pessoais e sensíveis de crianças e adolescentes,
          Você, na figura de responsável legal, consente, no momento da inserção dos dados, conforme
          informado expressamente na data da inserção e transmissão dos dados, com o tratamento dos
          dados da criança e do adolescente, pela <b>youcheck.in</b>, para permitir a adequada
          prestação dos serviços propostos pela <b>youcheck.in</b>.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Endereços IP, cookies e identificadores</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Ao visitar as nossas plataformas (WebApp, balcão de check-in self-service e o nosso
          site), poderemos coletar informações de seu computar ou de seu dispositivo móvel, como
          dados sobre a sessão, endereço IP, o software do navegador da Web, o site de referência,
          datas e horas das interações com a nossa plataforma e sua geolocalização. Podemos, também,
          coletar informações sobre sua atividade on-line, como o conteúdo visualizado, as páginas
          visualizadas e as pesquisas que efetuou. Nosso objetivo sobretudo é ajudar-nos a entender
          os interesses e preferências de nossos clientes, personalizar e melhorar a experiência de
          navegação dos clientes na plataforma e apresentar anúncios que desperte seu interesse.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >A <b>youcheck.in </b>poderá coletar essas informações por meio de cookies e outras
          tecnologias similares contidas nas plataformas proprietária. Os cookies associam-se apenas
          com um usuário anônimo e seu computador, não permitindo deduzir dados pessoais daquele que
          acessou a plataforma. Na verdade, os cookies permitem que a <b>youcheck.in </b>reconheça
          os clientes cadastrados logo após seu cadastro, sem que tenham que realizar o login em
          cada visita na plataforma. Os cookies ou outras ferramentas tecnológicas análogas também
          permitem que terceiros possam coletar informações sobre as suas atividades on-line. Você
          poderá configurar o seu navegadora para ser avisado de recepção de cookies e para impedir
          a instalação de cookies. Nesse caso, Você deve consultar as instruções e manuais do seu
          navegador para ampliar essa informação. Você poderá navegar em todas as plataformas da
          <b>youcheck.in</b>, sem permitir a instalação de cookies enviados, mas, a navegação e a
          sua experiência poderão ficar prejudicadas caso Você desative os cookies.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Dados fornecidos por terceiros</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Além dos dados pessoais e dados pessoais sensíveis acima mencionados, a
          <b>youcheck.in </b>poderá receber dos parceiros que são parte da prestação dos serviços,
          como por exemplo, os provedores de acomodação, os empresas que gerenciam o pagamento
          online, entre outros, informações sobre o status do check in, check out, estadia e
          detalhes de consumo na hospedagem para fins de processamento de pagamento. As plataformas
          de terceiros acessíveis a partir das plataformas da <b>youcheck.in </b>estão sob a
          responsabilidade de terceiros. A <b>youcheck.in </b>não será responsável pelos pedidos
          e/ou fornecimento de dados pessoais em plataformas de terceiros. Recomendamos que Você
          consulte as respectivas políticas de privacidade de tais plataformas para se informar de
          forma adequada a respeito do uso de seus dados pessoais por outras plataformas ou outras
          ferramentas.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Dados fornecidos para terceiros</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >A <b>youcheck.in </b>transfere os dados pessoais que Você forneceu para realizar o check
          in, check out perante o provedor de acomodação. Serão compartilhados os dados pessoais que
          Você nos forneceu e os nomes das pessoas que estão acompanhando Você.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Em algumas circunstâncias, a <b>youcheck.in </b>poderá fornecer ao provedor de acomodação
          informações passadas adicionais sobre Você, incluindo se já realizou alguma reserva ou
          estadia no passado. O provedor de acomodação, às vezes, poderá compartilhar seus dados
          pessoais com partes relacionadas, isto é, com outras entidades que formam um grupo
          econômico ou que tenha participação societária.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >A <b>youcheck.in </b>poderá utilizar serviços de terceiros prestadores de serviços,
          incluindo, mas, não se limitando à pesquisa de mercado, serviços de marketing, detecção e
          prevenção de fraudes.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >A <b>youcheck.in </b>poderá ser impelida pelas autoridades legais a fornecer os dados
          pessoais que Você nos vier a fornecer, conforme previsão legal ou por determinação
          judicial.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Finalidade da coleta e tratamento dos seus dados</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >A <b>youcheck.in </b>coleta e dá tratamento aos dados que Você forneceu para as seguintes
          finalidades:</span
        >
      </p>

      <p class="MsoListParagraphCxSpFirst" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Realização do cadastro de prévio check-in na <b>WebApp;</b></span
        >
      </p>

      <p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Realização do<b> </b>cadastro<b> </b>o check-in e o check-out no
          <b>balcão de check-in self-service</b>;</span
        >
      </p>

      <p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Intermediação com os prestadores de serviços (provedores de acomodação, empresas que
          gerenciam pagamentos <i>online</i>;</span
        >
      </p>

      <p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >d.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Análise de ocorrência de fraudes;</span
        >
      </p>

      <p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >e.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Envios de comunicação de marketing;</span
        >
      </p>

      <p class="MsoListParagraphCxSpLast" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >f.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Análise do seu perfil para melhoria dos serviços.
        </span>
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Prazo de retenção dos dados</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >A <b>youcheck.in</b> utilizará de seus melhores esforços para que os dados que Você
          disponibilizou sejam mantidos atualizados e aqueles dados que forem desnecessários ou
          excessivos serão excluídos.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >A retenção dos seus dados pessoais serão feitas pela <b>youcheck.in </b>pelo prazo que
          for necessário para servir à finalidade para a qual foram coletados e para permitir que
          Você use os serviços de forma reiterada.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Os dados também poderão ser retidos pelo prazo correspondente ao período de prescrição
          legal aplicável à finalidade para a qual foram coletados.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >O término do tratamento dos dados pessoais também poderá ocorrer nas seguintes
          hipóteses:</span
        >
      </p>

      <p class="MsoListParagraphCxSpFirst" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Pela sua manifestação nesse sentido, especialmente na hipótese em que a
          <b>youcheck.in </b>recebeu o consentimento para determinado tratamento de dados pessoais,
          se houver; ou</span
        >
      </p>

      <p class="MsoListParagraphCxSpLast" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Em caso de determinação legal ou judicial.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Em todos os casos acima, ressalvadas as hipóteses estabelecidas pela lei ou pelo presente
          Termo de Privacidade, os dados pessoais serão eliminados ou anonimizados de modo que os
          dados percam por completo a possibilidade de identificá-lo.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Os direitos do titular dos dados pessoais</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Como titular dos dados pessoais objeto de tratamento pela <b>youcheck.in</b>, Você possui
          os seguintes direitos, além de outros assegurados pela legislação aplicável:</span
        >
      </p>

      <p class="MsoListParagraphCxSpFirst" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Confirmação da existência de tratamento de dados pessoais</span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >. Você pode requisitar e validar seus dados fornecidos.</span
        >
      </p>

      <p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Acesso aos dados pessoais</span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;
line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >. Você poderá requisitar o acesso aos seus dados pessoas coletados e que estejam
          armazenados. Quando o tratamento for justificado por meio de seu consentimento e na
          execução dos serviços, Você poderá solicitar ainda uma cópia eletrônica integral de seus
          dados pessoais.</span
        >
      </p>

      <p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Correção de dados incompletos, inexatos ou desatualizados</span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >. Você poderá solicitar e autorizar que os dados pessoas que tratamos sejam precisos,
          atualizados e corrigidos.</span
        >
      </p>

      <p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >d.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Informações sobre uso compartilhado de dados</span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:
12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >. Todas as informações de compartilhamento dos dados pessoais com terceiros estão
          dispostas neste <b>Termo de Privacidade.</b></span
        >
      </p>

      <p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >e.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Eliminação dos dados pessoais</span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;
line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >. Você poderá solicitar a eliminação de dados pessoais que tenham sido coletadas a partir
          de seu consentimento, a qualquer momento, mediante manifestação gratuita.</span
        >
      </p>

      <p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >f.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Revogação do consentimento</span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:
107%;font-family:"Futura Lt BT",sans-serif'
          >. Você poderá revogar qualquer consentimento declarado para tratar os dados fornecidos, a
          qualquer momento, mediante manifestação gratuita.</span
        >
      </p>

      <p class="MsoListParagraphCxSpMiddle" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >g.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Eliminação, oposição, anonimização ou bloqueio dos dados pessoais.
          </span></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Caso Você não concorde com o tratamento dos dados fornecidos, terá o direito de se opor
          ou solicitar a anonimização, eliminação ou bloqueio dos dados.</span
        >
      </p>

      <p class="MsoListParagraphCxSpLast" style="text-align:justify;text-indent:-.25in">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >h.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp; </span></span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Reclamação à Autoridade. Você tem o direito de apresentar uma reclamação à Autoridade
          Nacional de Proteção de Dados – ANPD ou a outro órgão competente se considerar que o
          tratamento de seus dados pessoais infringe qualquer disposição da lei aplicável.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Para obtenção de mais informações sobre os direitos ou para exercer qualquer um desses
          direitos, Você poderá entrar em contato com a <b>youcheck.in </b>por meio do nosso canal
          de contato: dpo@youcheck.in.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Proteção de informações e dados pessoais</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Ao nos confiar seus dados pessoais, sua privacidade será garantida por intermédio de
          controles de segurança em sistemas e procedimentos adequados para protegê-los e
          salvaguardá-los. Apenas as pessoas autorizadas terão permissão para acessar seus dados
          pessoais.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Atualização do Termo de Privacidade</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Este Termo de Privacidade poderá vir a ser atualizado. Na hipótese de alteração, Você
          receberá uma notificação da mudança realizada, antes que as atividades a serem realizadas
          pela <b>youcheck.in</b> tenha início.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Este Termo de Privacidade foi atualizado e revisado em Fevereiro de 2021.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Este documento foi elaborado com base na legislação aplicável sobre segurança da
          informação, privacidade e proteção de dados, inclusive (sempre e quando aplicáveis) a
          Constituição Federal, o Código de Defesa do Consumidor, o Código Civil, o Marco Civil da
          Internet (Lei Federal nº 12.965/2014), seu decreto regulamentador (Decreto nº 8.771/2016),
          a Lei Geral de Proteção de Dados (Lei Federal nº 13.709/2018), e demais normas setoriais e
          gerais sobre o tema.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >Controladora dos dados e informações de contato</span
          ></b
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <a name="_Hlk64650343"
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
            >A</span
          ></a
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
        >
          YOUCHECK IN TECNOLOGIA LTDA, com sede na Avenida Paulista, 1159, conjunto 1507, Bela
          Vista, CEP 01311-921, Cidade de São Paulo, Estado de São Paulo, aqui nominada como </span
        ><b
          ><span
            lang="PT-BR"
            style='font-size:12.0pt;line-height:
107%;font-family:"Futura Lt BT",sans-serif'
            >youcheck.in</span
          ></b
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >, é </span
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:
"Futura Lt BT",sans-serif'
          >controlado</span
        ><span
          lang="PT-BR"
          style='font-size:
12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >ra dos dados que Você forneceu e a quem compete tomar as decisões referentes ao
          tratamento dos seus dados pessoais.</span
        >
      </p>

      <p class="MsoNormal" style="text-align:justify">
        <span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;font-family:"Futura Lt BT",sans-serif'
          >Quaisquer assuntos relacionados a esse Termo de Privacidade ou quaisquer outros assuntos
          relativos à proteção de dados pessoais devem ser endereçados ao seguinte contato: </span
        ><span
          lang="PT-BR"
          style='font-size:12.0pt;line-height:107%;
font-family:"Futura Lt BT",sans-serif'
          >dpo@youcheck.in</span
        >
      </p>
    </div>
    <b-button @click="close">Ok</b-button>
  </b-modal>
</template>

<script>
export default {
  name: "PrivacyModal",
  methods: {
    close() {
      this.$bvModal.hide("privacy-modal");
    }
  }
};
</script>

<style lang="scss">
#privacy-modal.modal {
  position: fixed;
  height: 100%;
  z-index: 1050;

  .modal-body {
    display: flex;
    flex-direction: column;
    padding: 40px;

    .btn {
      margin-left: 0;
      margin-right: 0;
      margin-top: 20px;
      font-size: 14px;
    }

    iframe {
      height: 400px;
    }
  }

  /* Style Definitions */
  p.MsoNormal,
  li.MsoNormal,
  div.MsoNormal {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 8pt;
    margin-left: 0in;
    line-height: 107%;
    font-size: 11pt;
  }
  a:link,
  span.MsoHyperlink {
    text-decoration: underline;
  }
  p.MsoListParagraph,
  li.MsoListParagraph,
  div.MsoListParagraph {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 8pt;
    margin-left: 0.5in;
    line-height: 107%;
    font-size: 11pt;
  }
  p.MsoListParagraphCxSpFirst,
  li.MsoListParagraphCxSpFirst,
  div.MsoListParagraphCxSpFirst {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 0.5in;
    line-height: 107%;
    font-size: 11pt;
  }
  p.MsoListParagraphCxSpMiddle,
  li.MsoListParagraphCxSpMiddle,
  div.MsoListParagraphCxSpMiddle {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 0in;
    margin-left: 0.5in;
    line-height: 107%;
    font-size: 11pt;
  }
  p.MsoListParagraphCxSpLast,
  li.MsoListParagraphCxSpLast,
  div.MsoListParagraphCxSpLast {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 8pt;
    margin-left: 0.5in;
    line-height: 107%;
    font-size: 11pt;
  }
  .MsoPapDefault {
    margin-bottom: 8pt;
    line-height: 107%;
  }
  @page WordSection1 {
    size: 595.3pt 841.9pt;
    margin: 70.85pt 85.05pt 70.85pt 85.05pt;
  }
  div.WordSection1 {
    page: WordSection1;
  }
  /* List Definitions */
  ol {
    margin-bottom: 0in;
  }
  ul {
    margin-bottom: 0in;
  }
}
</style>
