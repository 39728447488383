<template>
    <div class="custom-list">
      <h1>{{ $t("message.crewListTitle") }}</h1>
      <CrewRecordDownload @handleLoading="handleLoading" />
      <div class="row px-15 date-filters-row mt-4">
        <b-form-datepicker
          v-model="initDate"
          @context="initChange"
          class="mb-2"
          locale="pt-BR"
          :hide-header="true"
          placeholder="Data"
          size="lg"
          label-help=""
          selected-variant="warning"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        ></b-form-datepicker>    
      </div>
      <b-card bg-variant="dark" no-body class="table-holder">
        <b-table
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          responsive
          striped
          borderless
          dark
          sticky-header="calc(100vh - 380px)"
          table-variant="dark"
          @scroll.native="scrollHandler"
          @row-clicked="handleDownload"
        ></b-table>
        <transition name="fade">
          <app-loader v-if="isLoading" :isAdmin="true" />
        </transition>
      </b-card>
    </div>
  </template>
  
  <script>
  import moment from "moment";
  import CrewRecordDownload from "@/components/admin/CrewRecordDownload.vue";
  
  export default {
    name: "CrewCompanyReport",
    components: {
        CrewRecordDownload
    },
    computed: {
      hotelId() {
        return this.$store.getters.hotelId;
      }
    },
    data() {
      return {
        searchPlaceholder: this.$t("message.guestInfoSearchPlaceholder"),
        sortBy: "createdDate",
        sortDesc: true,
        fields: [
          {
            key: "createdAt",
            label: this.$t("message.downloadDate"),
            sortable: true,
            formatter: value => {
              if (value === null) {
                return "-";
              }
              return this.$d(new Date(value), "short");
            }
          },
          {
            key: "period",
            label: this.$t("message.selectedDay"),
            sortable: true
          },
          {
            key: "url",
            label: this.$t("message.downloadLink"),
            sortable: true,
            formatter: () => {
              return "Baixar";
            }
          }
        ],
        items: [],
        hasReachedEnd: false,
        isLoading: false,
        page: 1,
        pageSize: 20,
        searchString: "",
        initDate: "",
        endDate: ""
      };
    },
    methods: {
      filterChangedHandler(value) {
        this.searchString = value;
        this.loadItems();
      },
      scrollHandler(event) {
        if (this.hasReachedEnd) {
          return;
        }
  
        const list = event.target;
        if (list.offsetHeight + list.scrollTop >= list.scrollHeight - 100) {
          this.nextPage();
        }
      },
      nextPage() {
        if (this.isLoading) {
          return;
        }
        this.page += 1;
        this.loadItems(false);
      },
      loadHotelSettings() {
        this.handleLoading(true);
  
        this.$API.hotel
          .getSettings()
          .then(response => {
            const { id } = response.data;
  
            this.$store.dispatch("SET_HOTEL_ID", id);
          })
          .finally(() => {
            this.handleLoading(false);
            this.loadItems();
          });
      },
      loadItems(reload = true) {
        if (this.isLoading) {
          return;
        }
        if (reload) {
          this.items = [];
          this.page = 1;
        }
        this.handleLoading(true);
        const params = this.getListParams();
  
        this.$API.admin
          .getCrewReports(params)
          .then(data => {
            data.data.forEach(item => {
              const period = `${moment(item.startCreatedAt).format("DD/MM/YYYY")}`;
  
              const row = {
                createdAt: item.createdAt,
                url: item.url,
                period
              };
              this.items.push(row);
            });
            this.hasReachedEnd = !data.hasNextPage;
          })
          .catch(() => {
            this.hasReachedEnd = true;
          })
          .finally(() => {
            this.handleLoading(false);
          });
      },
      getListParams() {
        const value = {
          page: this.page,
          pageSize: this.pageSize,
          date: this.initDate,
        };
        return value;
      },
      initChange(ctx) {
        this.initDate = ctx.selectedYMD;
  
        if (this.endDate) this.loadItems();
      },
      endChange(ctx) {
        this.endDate = ctx.selectedYMD;
  
        if (this.initDate) this.loadItems();
      },
      handleDownload(item) {
        const { url } = item;
        const a = document.createElement("a");
  
        a.href = url;
        a.click();
        a.remove();
      },
      handleLoading(value) {
        this.isLoading = value;
      }
    },
    created() {
      this.loadHotelSettings();
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .px-15 {
    padding: 0px 15px;
  }
  
  .date-filters-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
  </style>
  