export const getCardBrand = cardNumber => {
  const cardnumber = cardNumber.replace(/[^0-9]+/g, "");

  const cards = {
    elo: /^((((636368)|(438935)|(504175)|(451416)|(636297)|(401178)|(401179)|(457393)|(457631)|(457632)|(627780)|(655001)|(651652)|(651653)|(651654)|(650485)|(650486)|(650487)|(650488)|(506699)|(506778)|(509000)|(509999)|(655003))\d{0,10})|((5067)|(4576)|(4011)|(6550))\d{0,12})/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}/,
    maestro: /^(?:5[0678]\d\d|6304|6390|67\d\d)\d{8,15}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    dinners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    aura: /^(5078\d{2})(\d{2})(\d{11})$/,
    amex: /^3[47][0-9]{13}/,
    hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    master: /^5[1-5][0-9]{14}/,
    visa: /^4[0-9]{12}(?:[0-9]{3})/
  };

  for (let flag in cards) {
    if (cards[flag].test(cardnumber)) {
      return flag;
    }
  }
  return "Unknown";
};
